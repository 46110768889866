import { createSlice } from '@reduxjs/toolkit';

export interface AuthState {
    user: any;
    isLoading: boolean;
    isAuth: boolean;
    userCongitoObj: any;
}

const initialState: AuthState = {
    user: null,
    isLoading: false,
    isAuth: false,
    userCongitoObj: null,
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        resetAuth: () => ({
            ...initialState,
        }),
        authPending: (state) => ({
            ...state,
            isLoading: true,
        }),
        authSuccess: (state, { payload }) => ({
            ...state,
            isLoading: false,
            userCongitoObj: payload,
            user: payload,
            isAuth: true,
        }),
        authFail: (state) => ({
            ...state,
            ...initialState,
        }),
        setAuthUser: (state, { payload }) => ({
            ...state,
            user: payload,
            isAuth: true,
        }),
    },
});

export const { authPending, authSuccess, authFail, resetAuth, setAuthUser } = authSlice.actions;
export default authSlice.reducer;
