/**
 * When component is used to handle the && condition in JSX it makes the code easy for reading
 * e.g {portals && <>JSX code here</>} can be handled with -
 * <When isTrue={portals}>
 *     <>JSX code here</>
 * </When>
 */
import React from 'react';

interface Props {
    isTrue: any;
    children: any;
}

const When = React.memo(({ isTrue, children }: Props) => {
    if (!isTrue) return null;
    return <>{isTrue && <>{children}</>}</>;
});
export default When;
