export const TOKEN_HEADER = {
    headers: {
        'Content-Type': 'application/json',
        Origin: 'localhost',
        'Access-Control-Request-Method': 'POST',
    },
};

const BASE_ENDPOINT = process.env.REACT_APP_API_BASE_URL;
export const ENDPOINT_DEV = `${BASE_ENDPOINT}/api/v1/`;
export const NO_DATA_FOUND_OR_INVALID_TEST_ID = 'No test results found or you have entered invalid testId';

export const AWS_EXPORTS = {
    Auth: {
        region: process.env.REACT_APP_AWS_REGION || 'us-east-1',
        userPoolId: process.env.REACT_APP_USER_POOL_ID || 'us-east-1_cxdilsXQO',
        userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID ?? '36aitheimoqjvckf39erm7kl6k',
        cookieStorage: {
            domain: process.env.REACT_APP_AMPLIFY_COOKIE_DOMAIN,
            path: '/',
            expires: 365,
            sameSite: 'strict',
            secure: process.env.REACT_APP_AMPLIFY_COOKIE_DOMAIN !== 'localhost',
        },
        oauth: {
            responseType: 'code',
            scope: ['email', 'profile', 'openid'],
            redirectSignIn: `${process.env.REACT_APP_DOMAIN || 'http://localhost:3000'}/redirect`,
            redirectSignOut: `${process.env.REACT_APP_DOMAIN || 'http://localhost:3000'}/login`,
            domain: process.env.REACT_APP_USER_POOL_DOMAIN || 'vcs-platform-dev.auth.us-east-1.amazoncognito.com',
        },
    },
};
