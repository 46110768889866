import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    message: '',
    messageData: null,
    urlCheckData: [],
    presenceData: [],
};

const ablySlice = createSlice({
    name: 'ably',
    initialState,
    reducers: {
        setMessageForAbly: (state, action) => {
            state.message = action.payload.message;
            state.messageData = action.payload.messageData;
        },
        setUrlCheckData: (state, action) => {
            state.urlCheckData = action.payload;
        },
        setPresenceData: (state, action) => {
            state.presenceData = action.payload;
        },
    },
});

export const { setMessageForAbly, setUrlCheckData, setPresenceData } = ablySlice.actions;

export default ablySlice.reducer;
