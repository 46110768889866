import { PORTAL_STATES } from 'utils';
const NEW_REGISTRATION_INTEGRATION_TIMESTAMP = 1659695841000;

interface StatusChipProps {
    currentPortal: any;
}

function PortalStatusChip({ currentPortal }: StatusChipProps) {
    if (!currentPortal) return null;

    const { isArchived, isActivated, is_approved, portalConnectionStatus, createdOn } = currentPortal;

    const isActivatedOrApproved = isActivated || is_approved;

    const isPendingActivation =
        (('isActivated' in currentPortal && !isActivated) || portalConnectionStatus === PORTAL_STATES.PENDING) &&
        portalConnectionStatus !== PORTAL_STATES.DISCONNECTED;

    const isBeforeIntegrationPendingActivation =
        'is_approved' in currentPortal &&
        !is_approved &&
        createdOn < NEW_REGISTRATION_INTEGRATION_TIMESTAMP &&
        !('isActivated' in currentPortal) &&
        portalConnectionStatus !== PORTAL_STATES.DISCONNECTED;

    // Handle archived status.
    if (isArchived) {
        return <div className="status-text pending-activation body5-secondary">Archived</div>;
    }

    // Handle pending activation status.
    if (isPendingActivation || isBeforeIntegrationPendingActivation) {
        return <div className="status-text pending-activation body5-secondary">Pending Activation</div>;
    }
    // Define status mappings for portalConnectionStatus
    const statusMap = {
        [PORTAL_STATES.IN_CALL]: { text: 'In Connection', className: 'in-connection' },
        [PORTAL_STATES.ONLINE]: { text: 'Available', className: 'available' },
        [PORTAL_STATES.OFFLINE]: { text: 'Offline', className: 'offline' },
        [PORTAL_STATES.IN_MAINTENANCE]: { text: 'In Maintenance', className: 'in-maintenance' },
        [PORTAL_STATES.ERROR]: { text: 'Error', className: 'error' },
        [PORTAL_STATES.DISCONNECTED]: { text: 'Disconnected', className: '' },
        [PORTAL_STATES.BOOKED]: { text: 'Booked', className: 'pending-activation' },
    };

    // Get status from the map
    const status = statusMap[portalConnectionStatus];

    // Handle disconnected and other statuses
    if (portalConnectionStatus === PORTAL_STATES.DISCONNECTED) {
        return <div className="status-text body5-secondary">Disconnected</div>;
    }

    // Render other statuses if the portal is activated or approved
    if (isActivatedOrApproved && status) {
        return <div className={`status-text ${status.className} body5-secondary`}>{status.text}</div>;
    }

    return null;
}

export default PortalStatusChip;
