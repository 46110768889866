import axios, { AxiosRequestConfig } from 'axios';
import { resetAuth } from '../redux-toolkit/reducer/authReducer';
import { setLoggedInUser } from '../redux-toolkit/reducer/userReducer';
import { setEmptyToast } from '../redux-toolkit/reducer/toastReducer';
import { getAccessToken, signOut } from '../utils';
import { ENDPOINT_DEV } from './configs';

const axiosInstance = axios.create({
    baseURL: ENDPOINT_DEV,
    headers: {
        'Content-Type': 'application/json',
    },
});

export const setupInterceptors = (history: any, dispatch: any) => {
    // Add a request interceptor
    axiosInstance.interceptors.request.use(
        (confA: AxiosRequestConfig) => {
            const conf = confA as Config;
            if (!conf?.custom?.excludeTokenIdFromHeader) {
                const accessToken = getAccessToken();
                if (!conf?.headers) {
                    conf.headers = {};
                }
                conf.headers['authorization'] = `Bearer ${accessToken}`;
            }
            return conf;
        },
        (error) => {
            Promise.reject(error);
        },
    );
    axiosInstance.interceptors.response.use(
        (response) =>
            // Edit response config
            {
                return response;
            },
        async (error) => {
            const statusCode = error?.response?.status;
            if (
                statusCode === 401 ||
                (statusCode === 500 && error?.response?.data?.message.indexOf('not authorized') !== -1)
            ) {
                console.log('interceptor not authorized');
                await signOut();
                dispatch(resetAuth());
                dispatch(setLoggedInUser({}));
                dispatch(setEmptyToast());
                history.push({ pathname: '/login' });
            }
            return Promise.reject(error);
        },
    );
};

export default axiosInstance;

export interface TAxiosCustomConfig {
    excludeTokenIdFromHeader?: boolean;
}

export interface Config extends AxiosRequestConfig {
    custom?: TAxiosCustomConfig;
}

export async function apiGet<T = any>(resource: string, config?: AxiosRequestConfig) {
    return await axiosInstance.get<T>(resource, config);
}

export async function apiPost<T = any>(resource: string, data?: any, config?: AxiosRequestConfig) {
    return axiosInstance.post<T>(resource, data, config);
}

export async function apiDelete<T = any>(resource: string, config?: AxiosRequestConfig) {
    return axiosInstance.delete<T>(resource, config);
}

export async function apiPatch<T = any>(resource: string, data?: any, config?: AxiosRequestConfig) {
    return axiosInstance.patch<T>(resource, data, config);
}

export async function apiPut<T = any>(resource: string, data?: any, config?: AxiosRequestConfig) {
    return axiosInstance.put<T>(resource, data, config);
}

export async function apiUpload<T = any>(
    resource: string,
    data?: any,
    config?: AxiosRequestConfig,
    progressCallback?: (progress: number) => void,
) {
    return axiosInstance.post<T>(resource, data, {
        ...config,
        headers: {
            ...config?.headers,
            'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent: { loaded: number; total: number }) => {
            const uploadPercentage = parseInt(
                String(Math.round((progressEvent.loaded / progressEvent.total) * 100)),
                10,
            );

            progressCallback && progressCallback(uploadPercentage);
        },
    });
}
