import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { addCuratorByOrgIdPortalIdAPI } from '../../api/addCuratorByOrgIdPortalIdAPI';
import { getAllCuratorsByPortalIdAPI } from '../../api/getAllCuratorsByPortalIdAPI';
import { CuratorModel } from '../model/curatorModel';

const initialState = {
    allCurators: {
        data: [] as CuratorModel[],
        pager: {},
    },
    loading: false,
    error: false,
    errorObj: {} as any,
    selectedOrgId: '',
    curator: {} as CuratorModel,
};

export const getAllCuratorsByPortalId = createAsyncThunk('curator/getAllCuratorsByPortal', async (obj: any) => {
    const response = await getAllCuratorsByPortalIdAPI(obj);
    return response.data;
});

export const addCuratorByOrgIdPortalId = createAsyncThunk('curator/addCuratorByOrgIdPortalId', async (curator: any) => {
    delete curator?.curator?.pk;
    delete curator?.curator?.sk;
    delete curator?.curator?.org_id;
    return await addCuratorByOrgIdPortalIdAPI(curator);
});

const curatorSlice = createSlice({
    name: 'curator',
    initialState,
    reducers: {
        setResetCurator(state, action) {
            state.curator = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllCuratorsByPortalId.pending, (state) => {
                // action is inferred correctly here if using TS
                state.loading = true;
                state.error = false;
                state.curator = {} as CuratorModel;
            })
            .addCase(getAllCuratorsByPortalId.fulfilled, (state, action) => {
                state.loading = false;
                state.error = false;
                state.allCurators = action.payload;
                state.curator = {} as CuratorModel;
            })
            .addCase(getAllCuratorsByPortalId.rejected, (state) => {
                state.loading = false;
                state.error = true;
                state.curator = {} as CuratorModel;
            })
            .addCase(addCuratorByOrgIdPortalId.pending, (state) => {
                // action is inferred correctly here if using TS
                state.loading = true;
                state.error = false;
            })
            .addCase(addCuratorByOrgIdPortalId.fulfilled, (state, action) => {
                state.loading = false;
                state.error = false;
                state.curator = action.payload.data;
            })
            .addCase(addCuratorByOrgIdPortalId.rejected, (state, action) => {
                state.loading = false;
                state.error = true;
                state.errorObj = action.error;
            });
        // You can chain calls, or have separate `builder.addCase()` lines each time
        /* .addCase(decrement, (state, action) => {}) */
        // You can match a range of action types
        /* .addMatcher(
            isRejectedAction,
            // `action` will be inferred as a RejectedAction due to isRejectedAction being defined as a type guard
            (state, action) => {}
          ) */
        // and provide a default case if no other handlers matched
        /* .addDefaultCase((state, action) => {}) */
    },
});
export const { setResetCurator } = curatorSlice.actions;
export default curatorSlice.reducer;
