import Ably from 'ably';
import { ENDPOINT_DEV } from 'api/configs';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';

const useAblyClient = () => {
    const [ablyClient, setAblyClient] = useState(null);
    const { loggedInUserStore } = useSelector(
        ({ user }) => ({
            loggedInUserStore: user.loggedInUser,
        }),
        isEqual,
    );

    const createTokenRequest = async () => {
        if (loggedInUserStore?.email) {
            const response = await axios.post(`${ENDPOINT_DEV}ably/generate-token/${loggedInUserStore?.email}-(admin)`);
            return response.data.token;
        }
    };

    useEffect(() => {
        let client = null;
        const fetchData = async () => {
            try {
                if (loggedInUserStore?.email) {
                    client = new Ably.Realtime({
                        authCallback: async (tokenParams, callback) => {
                            try {
                                // eslint-disable-next-line no-console
                                console.log('Fetching ably token...');
                                const tokenRequest = await createTokenRequest(); // Make a network request to your server
                                callback(null, tokenRequest);
                            } catch (error) {
                                callback(error, null);
                            }
                        },
                    });
                    setAblyClient(client);
                }
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error('Error fetching token:', error);
            }
        };

        fetchData();

        // Cleanup function
        return () => {
            try {
                if (client && client.connection.state === 'connected') {
                    client.connection.off();
                }
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error('Error closing Ably connection:', error);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loggedInUserStore?.email]);

    return ablyClient;
};

export default useAblyClient;
