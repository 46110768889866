import { useRef } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    // getPortalByOrgIdPortalId,
    // getPortalByOrgIdPortalIdStatus,
    getAgentUpdateStatus,
} from 'redux-toolkit/reducer/portalReducer';

export default function useVersionUpdateHook({ message }) {
    const dispatch = useDispatch();

    const { getPortalAgentUpdateStatus, portal } = useSelector(({ portal }) => ({
        getPortalAgentUpdateStatus: portal.getPortalAgentUpdateStatus,
        portal: portal.portal,
    }));
    const portalStatusIntervalRef = useRef(null);

    // const handleAgentUpdate = () => {
    //     dispatch(getPortalByOrgIdPortalId({ orgId: portal?.org_id, portalId: portal.portal_id }));
    //     dispatch(getPortalByOrgIdPortalIdStatus({ orgId: portal?.org_id, portalId: portal.portal_id }));
    // };

    const getPortalUpdateStatus = () => {
        dispatch(getAgentUpdateStatus({ orgId: portal?.org_id, portalId: portal.portal_id }));
    };

    useEffect(() => {
        if (getPortalAgentUpdateStatus?.status === 'error' || getPortalAgentUpdateStatus?.status === 'success') {
            clearInterval(portalStatusIntervalRef.current);
        }
    }, [getPortalAgentUpdateStatus?.status]);

    useEffect(() => {
        const command = message?.data?.message || '';
        // const commandData = message?.data?.data || {};
        let interval;
        // if (
        //     (command === 'agent-update-available' || command === 'auto-update-complete') &&
        //     commandData?.portal_id === portal.portal_id
        // ) {
        //     handleAgentUpdate();
        // }

        if (command === 'portal-version-upgrade') {
            console.log('clear getPortalUpdateStatus');
            clearInterval(portalStatusIntervalRef.current);
        }
        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [message, dispatch]);

    useEffect(() => {
        const command = message?.data?.message || '';
        if (command === 'install-agent-updates') {
            portalStatusIntervalRef.current = setInterval(() => {
                getPortalUpdateStatus();
            }, 3000);
        }
        return () => clearInterval(portalStatusIntervalRef.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [message, dispatch]);
}
