import { AxiosRequestConfig } from 'axios';
import { apiGet } from './axios';
import { ENDPOINT_DEV } from './configs';

interface Config extends AxiosRequestConfig {
    custom?: TAxiosCustomConfig;
}
interface TAxiosCustomConfig {
    excludeTokenIdFromHeader?: boolean;
}

export const getUserByTokenSignUpAPI = async (token: string) => {
    const config = {} as Config;
    config.custom = {
        excludeTokenIdFromHeader: true,
    };

    return await apiGet(`${ENDPOINT_DEV}invited-user/${token}`, config);
};
