import { apiGet } from './axios';
import { ENDPOINT_DEV } from './configs';

export const getListOfPortalsAPI = async (orgPortal: any) => {
    let endpoint = ENDPOINT_DEV + `organization/${orgPortal.orgId}/portal`;
    let queryParams = [];

    if (orgPortal.limit) {
        queryParams.push(`limit=${orgPortal.limit}`);
    }

    if (typeof orgPortal.getAllPortals !== 'undefined') {
        queryParams.push(`getAllPortals=${orgPortal.getAllPortals}`);
    }

    if (queryParams.length > 0) {
        endpoint += '?' + queryParams.join('&');
    }

    return await apiGet(endpoint);
};
