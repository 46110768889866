import React, { useState, useEffect, useRef } from 'react';

const GoogleAutoComplete = (props: any) => {
    const [query, setQuery] = useState('');
    const [places, setPlaces] = useState({});
    const [timeZone, setTimeZone] = useState({});
    const autoCompleteRef = useRef<HTMLInputElement>(null);
    let autoComplete: any;

    /**
     * When we search for the locations it will populate the location suggestion matched with the query
     */

    const initGPlacesAutocomplete = () => {
        if (autoCompleteRef?.current) {
            autoComplete = new window.google.maps.places.Autocomplete(autoCompleteRef.current, {
                types: ['(regions)'],
            });
            autoComplete.setFields(['address_components', 'formatted_address', 'geometry']);
            autoComplete.addListener('place_changed', async () => {
                const addressObject = await autoComplete.getPlace();
                const query = addressObject.formatted_address;
                setQuery(query);
                if (addressObject.hasOwnProperty('formatted_address')) {
                    setPlaces({
                        places: addressObject,
                        flag: true,
                        location: {
                            lat: addressObject.geometry.location.lat(),
                            lng: addressObject.geometry.location.lng(),
                        },
                    });

                    // Call function to fetch timezone
                    const timeZone = await fetchTimeZone(
                        addressObject.geometry.location.lat(),
                        addressObject.geometry.location.lng(),
                    );
                    setTimeZone(timeZone);
                } else {
                    setPlaces({ places: addressObject, flag: false });
                }
            });
        }
    };

    const handleKeyboardEvent = (e: any) => {
        if (e.target.value !== undefined && e.target.value !== null && e.target.value === '') {
            const places = { name: '' };
            const flag = false;
            setPlaces({ places, flag });
        }
    };

    const fetchTimeZone = async (lat: number, lng: number) => {
        try {
            const response = await fetch(
                `https://maps.googleapis.com/maps/api/timezone/json?location=${lat},${lng}&timestamp=${
                    Date.now() / 1000
                }&key=${process.env.REACT_APP_GOOGLE_PLACES_API_KEY}`,
            );
            const data = await response.json();
            return data.timeZoneId;
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error('Error fetching timezone:', error);
        }
    };

    useEffect(() => {
        /* if(document.scripts && document.scripts.length>0){

      const scrips = document.scripts as any;
      [...scrips].forEach((item:HTMLScriptElement) => {
        if(item.src.includes('libraries=places')) {
          return;
        }
      });
    } */
        initGPlacesAutocomplete();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setQuery(props.prefilled !== undefined && props.prefilled !== null ? props.prefilled : '');
    }, [props.prefilled]);

    useEffect(() => {
        if (Object.keys(places).length > 0) {
            props.updateLocation({ ...places, timeZone });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [places, timeZone]);

    return (
        <div className="search-location-input">
            <input
                ref={autoCompleteRef}
                onChange={(event) => {
                    setQuery(event.target.value);
                }}
                onKeyUpCapture={handleKeyboardEvent}
                placeholder="Location"
                className="form-control lg pac-container"
                value={query}
                disabled={props.disabled}
                autoComplete="true"
            />
            <span className="location-icon" />
        </div>
    );
};

export default GoogleAutoComplete;
