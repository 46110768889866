import { apiGet } from './axios';
import { ENDPOINT_DEV } from './configs';

export const getChartDataAPI = async (orgPortal: {
    orgId: string;
    portalId: string;
    activeTab: string;
    type: string;
}) => {
    return await apiGet(
        ENDPOINT_DEV +
            `analytics/${orgPortal.orgId}/portal/${orgPortal.portalId}/${orgPortal.type}/${orgPortal.activeTab}`,
    );
};
