import { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Auth } from 'aws-amplify';
import { useKeepMeLoggedIn } from './useKeepMeLoggedIn';
import { authPending, resetAuth } from '../redux-toolkit/reducer/authReducer';
import { signOut } from '../utils';
import { AWS_EXPORTS } from '../api/configs';
import { useHistory } from 'react-router-dom';
export const useCheckLoggedInUser = () => {
    const { keepMeLoggedIn } = useKeepMeLoggedIn();
    const history = useHistory();
    const dispatch = useDispatch();

    const callSignOut = (clientId: string) => {
        console.log('callSignOut---------------', clientId);
        Auth.signOut();
        const authConfig = Auth.configure();

        const { domain, redirectSignOut } = authConfig.oauth as { domain: string; redirectSignOut: string };
        const cognitoLogoutEndpoint = `https://${domain}/logout`;
        const logoutUrl = `${cognitoLogoutEndpoint}?client_id=${clientId}&logout_uri=${encodeURIComponent(
            redirectSignOut,
        )}`;
        signOut();
        localStorage.setItem('isRedirect', 'true');
        window.location.assign(logoutUrl);
    };

    const ionViewCanEnter = useCallback(async () => {
        const clientId = localStorage.getItem('clientId');
        const isRedirect = localStorage.getItem('isRedirect');
        try {
            const authenticatedUser = await Auth.currentAuthenticatedUser();
            // eslint-disable-next-line no-console
            console.log('authenticatedUser---------------', authenticatedUser);
            if (authenticatedUser) {
                const currentSession = await Auth.currentSession();
                // eslint-disable-next-line no-console
                console.log('currentSession---------------', currentSession);
                if (currentSession) {
                    dispatch(authPending());
                    keepMeLoggedIn(authenticatedUser, true);
                } else {
                    // eslint-disable-next-line no-console
                    console.log('currentSession not found---------------', currentSession);
                }
            }
        } catch (err: any) {
            if (err && err?.includes('The user is not authenticated')) {
                if (clientId && !isRedirect) {
                    callSignOut(clientId);
                } else if (!isRedirect) {
                    signOut();
                    dispatch(resetAuth());
                    if (window.location.pathname === '/') {
                        history.push({ pathname: '/login' });
                    }
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, keepMeLoggedIn]);

    useEffect(() => {
        const clientId = localStorage.getItem('clientId');
        ionViewCanEnter();
        if (clientId) {
            Auth.configure({
                Auth: {
                    ...AWS_EXPORTS.Auth,
                    userPoolWebClientId: clientId,
                },
            });
        } else {
            Auth.configure(AWS_EXPORTS);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);
};
