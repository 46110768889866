import { useLocation } from 'react-router-dom';
import React from 'react';

/**
 * @returns query params
 * const queryParam = useQuery();
 * queryParam.get("paramName");
 */
export default function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}
