import { apiGet } from './axios';
import { ENDPOINT_DEV } from './configs';
const oneDayEpochDiff = 24 * 60 * 60 * 1000;

export const getAllBookingsByOrgIdAPI = async (orgId: string, portalId: string, futureBookingsOnly: boolean) => {
    if (futureBookingsOnly) {
        const startTimeEpoch = new Date().getTime();
        return await apiGet(
            `${ENDPOINT_DEV}organization/${orgId}/booking/overlapping?participantPortalIds=${portalId}&expectedStartTime=${startTimeEpoch}&expectedEndTime=${
                startTimeEpoch + oneDayEpochDiff
            }`,
        );
    } else {
        return await apiGet(
            `${ENDPOINT_DEV}organization/${orgId}/booking/overlapping?participantPortalIds=${portalId}&forLogs=true`,
        );
    }
};
