import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAllCameraPreviewImages, ioConfigPostAPI, updateCameraPositionApi } from '../../api/ioConfigPostAPI';
import { IODeviceListModel } from '../model/portalModel';
import { PreviewDataI } from 'redux-toolkit/model/IOConfigModel';

const initialState = {
    loading: false,
    error: false,
    errorObj: {} as any,
    ioConfigUpdated: {} as any,
    ioDeviceList: {} as IODeviceListModel,
    ioConfigUpdateLoading: false,
    multiCameraConfig: {} as any,
    cameraPreview: {
        data: [],
        loading: false,
        error: false,
        errorObj: {},
    } as PreviewDataI,
    cameraArrangements: {
        data: [],
        loading: false,
        error: false,
        errorObj: {},
    } as PreviewDataI,
    cameraPreviewError: {
        error: false,
        message: null,
    },
};

/**
 * Update the IO resources for the portal
 */
export const updateIOConfigByPortal = createAsyncThunk(
    'ioConfig/updateIOConfigByPortal',
    async (io: any, { rejectWithValue }) => {
        try {
            return await ioConfigPostAPI(io);
        } catch (err: any) {
            if (!err.response) {
                throw err;
            }
            return rejectWithValue(err.response.data);
        }
    },
);

export const getAllCameraPreview = createAsyncThunk(
    'ioConfig/getAllCameraPreview',
    async (data: any, { rejectWithValue }) => {
        try {
            return await getAllCameraPreviewImages(data);
        } catch (err: any) {
            if (!err.response) {
                throw err;
            }
            return rejectWithValue(err.response.data);
        }
    },
);

export const updateCameraPositionsByPortal = createAsyncThunk(
    'ioConfig/updateCameraPositionsByPortal',
    async (data: any, { rejectWithValue }) => {
        try {
            return await updateCameraPositionApi(data);
        } catch (err: any) {
            if (!err.response) {
                throw err;
            }
            return rejectWithValue(err.response.data);
        }
    },
);

const ioConfigSlice = createSlice({
    name: 'ioConfig',
    initialState,
    reducers: {
        setIOConfigUpdatedNull: (state, action) => {
            state.ioConfigUpdated = action.payload;
        },
        setIoDeviceListEmpty: (state) => {
            state.ioDeviceList = {} as IODeviceListModel;
        },
        setPortalIoDeviceList: (state, action) => {
            if (action?.payload?.message) {
                state.ioDeviceList = { message: action.payload.message };
            } else {
                state.ioDeviceList = manipulateIODeviceList(action.payload);
            }
        },
        setMultiCameraConfig: (state, action) => {
            state.multiCameraConfig = action.payload;
        },
        setCameraPreviews: (state, action) => {
            state.cameraPreview = action.payload;
        },
        setCameraPreviewError: (state, action) => {
            state.cameraPreviewError = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(updateIOConfigByPortal.pending, (state) => {
                state.ioConfigUpdateLoading = true;
            })
            .addCase(updateIOConfigByPortal.fulfilled, (state, action) => {
                state.ioConfigUpdateLoading = false;
                state.error = false;
                state.ioConfigUpdated = action.payload.data;
            })
            .addCase(updateIOConfigByPortal.rejected, (state, action: any) => {
                state.ioConfigUpdateLoading = false;
                state.error = true;
                state.ioConfigUpdated = { message: action?.payload?.message };
            })
            .addCase(getAllCameraPreview.pending, (state) => {
                state.cameraPreview.loading = true;
                state.cameraPreview.error = false;
                state.cameraPreview.errorObj = {};
            })
            .addCase(getAllCameraPreview.fulfilled, (state, action) => {
                console.log('action.payload.data', action.payload);
                state.cameraPreview.loading = false;
                state.cameraPreview.error = false;
                state.cameraPreview.data = action.payload.data?.data;
            })
            .addCase(getAllCameraPreview.rejected, (state, action: any) => {
                state.cameraPreview.loading = false;
                state.cameraPreview.error = true;
                state.cameraPreview.errorObj = action.payload;
            })
            // Camera Arrangemnets
            .addCase(updateCameraPositionsByPortal.pending, (state) => {
                state.cameraArrangements.loading = true;
                state.cameraArrangements.error = false;
                state.cameraArrangements.errorObj = {};
            })
            .addCase(updateCameraPositionsByPortal.fulfilled, (state, action) => {
                state.cameraArrangements.loading = false;
                state.cameraArrangements.error = false;
            })
            .addCase(updateCameraPositionsByPortal.rejected, (state, action: any) => {
                state.cameraArrangements.loading = false;
                state.cameraArrangements.error = true;
                state.cameraArrangements.errorObj = action.payload;
            });
    },
});
export const {
    setIOConfigUpdatedNull,
    setIoDeviceListEmpty,
    setPortalIoDeviceList,
    setMultiCameraConfig,
    setCameraPreviews,
    setCameraPreviewError,
} = ioConfigSlice.actions;
export default ioConfigSlice.reducer;

export const manipulateIODeviceList = (responseData: any) => {
    // added to manipulate the device list to not show the deviceid present in the labels
    const finalObj = {} as any;
    const regex = /\s*\([\w\s-]+:\w+\)\s*/;
    if (responseData && Object.keys(responseData).length > 0) {
        for (const key in responseData) {
            if (responseData.hasOwnProperty(key) && ['audioInput', 'audioOutput', 'videoInput'].includes(key)) {
                finalObj[key] = [];
                // eslint-disable-next-line array-callback-return
                responseData[key].map((item: any) => {
                    if (
                        item.deviceLabel.toLowerCase().indexOf('default') === -1 &&
                        item.deviceLabel.toLowerCase().indexOf('communications') === -1 &&
                        item.deviceId.toLowerCase().indexOf('default') === -1 &&
                        item.deviceId.toLowerCase().indexOf('communications') === -1
                    ) {
                        const shouldBeHidden =
                            item.deviceLabel.toLowerCase().indexOf('virtual') >= 0 ||
                            item.deviceLabel.toLowerCase().indexOf('built-in') >= 0 ||
                            item.deviceLabel.toLowerCase().indexOf('hdmi') >= 0 ||
                            regex.test(item.deviceLabel.trim());
                        if (shouldBeHidden) {
                            item.toHide = true;
                            finalObj[key].push(item);
                        } else {
                            item.toHide = false;
                            finalObj[key].push(item);
                        }
                    }
                });
            }
        }
    }
    const videoList = addDeviceIdAndRemovePortId(finalObj['videoInput']);
    const audioInputList = addDeviceIdAndRemovePortId(finalObj['audioInput']);
    const audioOutputList = addDeviceIdAndRemovePortId(finalObj['audioOutput']);

    if (videoList?.length) {
        videoList.forEach((item: any) => {
            const itemFound = filterFromVideoList(item, audioInputList);
            if (itemFound !== -1) {
                audioInputList[itemFound].toHide = true;
            }
        });
    }
    if (videoList?.length) {
        videoList.forEach((item: any) => {
            const itemFound = filterFromVideoList(item, audioOutputList);
            if (itemFound && itemFound !== -1) {
                audioOutputList[itemFound].toHide = true;
            }
        });
    }
    finalObj['videoInput'] = videoList;
    finalObj['audioInput'] = audioInputList;
    finalObj['audioOutput'] = audioOutputList;
    finalObj['screens'] = responseData && responseData.screenDetails ? responseData.screenDetails : [];
    return finalObj;
};

const filterFromVideoList = (item: any, list: any) => {
    return list.findIndex(
        (videoItem: any) =>
            item.deviceLabelWithoutIds.includes(videoItem.deviceLabelWithoutIds) ||
            videoItem.deviceLabelWithoutIds.includes(item.deviceLabelWithoutIds),
    );
};

/**
 * Updates the labels by removing the code which has : and adds the first 6 chars from deviceId
 * @param list IO device list which needs changes in the labels
 * @returns updates list received from the params
 */
const addDeviceIdAndRemovePortId = (list: any) => {
    if (list?.length) {
        const newList: any[] = [];
        list.forEach((item: any) => {
            const newItem = JSON.parse(JSON.stringify(item));
            let label = item.deviceLabel?.replace(/\s*\([\w\s-]+:\w+\)\s*/g, '');
            newItem.deviceLabelWithoutIds = label; // created this to get comparison between video list and audio i/o device list.
            label += ' (' + item.deviceId.substr(0, 6) + ')';
            newItem.deviceLabel = label;
            newList.push(newItem);
        });
        return newList;
    } else return [];
};
