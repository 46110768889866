import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAllBookingsByOrgIdAPI } from '../../api/getAllBookingsByOrgIdAPI';

const initialState = {
    allBookingsByOrgId: {} as any,
    allBookingsByOrgIdLoading: false,
    allBookingsByOrgIdError: false,
    allBookingsByOrgIdErrorObj: {},
    futureBookingsForArchivePortal: false,
};

export const getAllBookingsByOrgId = createAsyncThunk(
    'organization/getAllBookingsByOrgId',
    async (obj: { orgId: string; portalId: string; futureBookingsOnly: boolean }) => {
        return await getAllBookingsByOrgIdAPI(obj.orgId, obj.portalId, obj.futureBookingsOnly);
    },
);

const bookingSlice = createSlice({
    name: 'booking',
    initialState,
    reducers: {
        setFutureBookingsForArchivePortal: (state, action) => {
            state.futureBookingsForArchivePortal = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllBookingsByOrgId.pending, (state) => {
                state.allBookingsByOrgIdLoading = true;
                state.allBookingsByOrgIdError = false;
            })
            .addCase(getAllBookingsByOrgId.fulfilled, (state, action) => {
                state.allBookingsByOrgIdLoading = false;
                state.allBookingsByOrgIdError = false;
                if (
                    action.payload.data &&
                    'isOverlapping' in action.payload.data &&
                    'overlappingSessions' in action.payload.data &&
                    action.payload.data.overlappingSessions.length > 0
                ) {
                    state.allBookingsByOrgId = reStructureOverLappingSession(
                        JSON.parse(JSON.stringify(action.payload.data)),
                    );
                } else {
                    state.allBookingsByOrgId = {};
                }
            })
            .addCase(getAllBookingsByOrgId.rejected, (state, action) => {
                state.allBookingsByOrgIdLoading = false;
                state.allBookingsByOrgIdError = true;
                state.allBookingsByOrgIdErrorObj = action.error;
            });
    },
});

const reStructureOverLappingSession = (responseData: any) => {
    const tempBookings = [] as any;
    responseData.overlappingSessions.forEach((item: any) => {
        // To push future booking which has not started yet
        if (
            item.isCreatedFromBooking &&
            item.hasOwnProperty('sessionLogs') &&
            item.sessionLogs.length === 0 &&
            item.sessionStatus === 'created'
        ) {
            tempBookings.push({ ...item });
        } else if (item.hasOwnProperty('sessionLogs') && item.sessionLogs.length > 0) {
            const baseObj = JSON.parse(JSON.stringify(item));
            let newStartTime = 0;
            let newEndTime = 0;
            let invitedPortalIds = [] as any;
            let finalSessionDurationInSec = 0;
            delete baseObj.sessionLogs;
            item.sessionLogs.forEach((logsItem: any) => {
                invitedPortalIds = [];
                newStartTime = 0;
                newEndTime = 0;
                logsItem.forEach((log: any) => {
                    switch (log.event) {
                        case 'participant-joined':
                            if (newStartTime === 0) {
                                newStartTime = log.eventOccuredAt;
                            }
                            if (invitedPortalIds.length < log.participantPortalIds.length) {
                                invitedPortalIds = log.participantPortalIds;
                            }
                            break;
                        case 'session-ended':
                            newEndTime = log.eventOccuredAt;
                            break;
                    }
                });
                let seconds = 0;
                if (newEndTime && newStartTime && newStartTime < newEndTime) {
                    seconds = (newEndTime - newStartTime) / 1000;
                }
                finalSessionDurationInSec += seconds;
                invitedPortalIds = [];
                newStartTime = 0;
                newEndTime = 0;
            });
            let finalMinsInStr = Number(Math.round(finalSessionDurationInSec / 60).toFixed(2));
            baseObj.duration = finalMinsInStr;
            tempBookings.push({ ...baseObj });
        } else {
            if (item.sessionStatus !== 'created') {
                let finalEndTime = item.endTime;
                if (item.sessionEndedAt) {
                    finalEndTime = item.sessionEndedAt;
                }
                if (finalEndTime && item.startTime && item.startTime < finalEndTime) {
                    const secs = (finalEndTime - item.startTime) / 1000;
                    item.duration = Number(Math.round(secs / 60).toFixed(2));
                }
                tempBookings.push({ ...item });
            }
        }
    });
    responseData.overlappingSessions = JSON.parse(JSON.stringify(tempBookings));
    return JSON.parse(JSON.stringify(responseData));
};

export const { setFutureBookingsForArchivePortal } = bookingSlice.actions;
export default bookingSlice.reducer;
