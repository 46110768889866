// Built-in
import React from 'react';

// Internal project-specific
import When from '../when/When';

const ConfigCard = (props: any) => {
    return (
        <div className="card-container">
            <div className="icon d-flex align-items-center justify-content-center">
                <i className={`${props.icon} resize`} />
            </div>
            <div className="content justify-content-center">
                <div className="upper-div">
                    <div className="heading4-primary">{props.heading}</div>
                    <div className="body1-primary">
                        {props.title}
                        <When
                            isTrue={
                                props.isDisabledObj !== null &&
                                props.isDisabledObj !== undefined &&
                                Object.keys(props.isDisabledObj).length > 0
                            }
                        >
                            <div className="body5-primary no-list-avail">
                                {props?.isDisabledObj?.message
                                    ? props?.isDisabledObj?.message
                                    : props?.portalConnectionStatus === 'offline'
                                    ? 'This portal is offline. Try again when the portal is online.'
                                    : null}
                            </div>
                        </When>
                    </div>
                </div>
            </div>
            <div className="lower-div ml-auto">
                <button
                    className="btn-custom btn-primary md"
                    disabled={
                        props.isDisabledObj !== null &&
                        props.isDisabledObj !== undefined &&
                        Object.keys(props.isDisabledObj).length > 0
                    }
                    onClick={() => props.onBtnClick()}
                >
                    <div className="custom1-primary">{props.btnText}</div>
                </button>
            </div>
        </div>
    );
};

export default ConfigCard;
