import { apiGet } from './axios';
import { ENDPOINT_DEV } from './configs';

export const getOrgAdminByOrgIdAPI = async (orgPortal: any) => {
    return await apiGet(
        ENDPOINT_DEV +
            `organization/${orgPortal.orgId}/org-admin/${orgPortal.email}` +
            (orgPortal.limit ? '?limit=' + orgPortal.limit : ''),
    );
};
