import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getScreenshotModel } from '../model/screenshotModel';
import { getScreenshotByPortalAPI } from './../../api/getScreenshotByPortalAPI';
import { requestPortalScreenshotAPI } from './../../api/requestPortalScreenshotAPI';

const initialState = {
    loading: false,
    error: false,
    errorObj: {} as any,
    reqLoading: false,
    reqError: false,
    reqErrorObj: {} as any,
    reqScreenshotRes: {} as any,
    screenshot: {} as getScreenshotModel | any,
    lastLatestLoading: false,
    lastLatestError: false,
    lastLatestErrorObj: {} as any,
};

export const getScreenshotByPortal = createAsyncThunk(
    'portal/getScreenshotByPortal',
    async (obj: any, { rejectWithValue }) => {
        try {
            return await getScreenshotByPortalAPI(obj);
        } catch (err: any) {
            if (!err.response) {
                throw err;
            }
            return rejectWithValue(err.response);
        }
    },
);

export const requestPortalScreenshot = createAsyncThunk('portal/requestPortalScreenshot', async (obj: any) => {
    return await requestPortalScreenshotAPI(obj);
});

const screenshotSlice = createSlice({
    name: 'screenshot',
    initialState,
    reducers: {
        setReqScreenshotRes(state: any) {
            state.reqScreenshotRes = {};
            state.reqLoading = false;
            state.reqError = false;
        },
        setLastLatestScreenshotRes(state: any) {
            state.screenshot = {};
            state.lastLatestLoading = false;
            state.lastLatestError = false;
            state.lastLatestErrorObj = {};
        },
    },
    extraReducers: (builder: any) => {
        builder
            .addCase(getScreenshotByPortal.pending, (state: any) => {
                state.lastLatestLoading = true;
                state.lastLatestError = false;
                state.screenshot = {};
            })
            .addCase(getScreenshotByPortal.fulfilled, (state: any, action: any) => {
                state.lastLatestLoading = false;
                state.lastLatestError = false;
                state.screenshot = action.payload.data;
            })
            .addCase(getScreenshotByPortal.rejected, (state: any, action: any) => {
                state.lastLatestLoading = false;
                state.lastLatestError = true;
                state.screenshot = {};
                state.lastLatestErrorObj = {
                    status: action?.payload?.status,
                    message: action?.payload?.data?.message,
                };
            })
            .addCase(requestPortalScreenshot.pending, (state: any) => {
                state.reqLoading = true;
                state.reqError = false;
                state.reqScreenshotRes = {};
            })
            .addCase(requestPortalScreenshot.fulfilled, (state: any, action: any) => {
                state.reqLoading = false;
                state.reqError = false;
                if (
                    action.payload &&
                    'response' in action.payload &&
                    'status' in action.payload.response &&
                    (action.payload.response.status === 503 || action.payload.response.status === 404)
                ) {
                    state.reqScreenshotRes.status = action.payload.response.status;
                    if (action.payload.response.status === 404) {
                        state.reqScreenshotRes.message = action.payload.response.data.message;
                    }
                    state.reqError = true;
                    /* state.reqScreenshotRes.objectUrl = 'img/2.webm'; */
                } else {
                    state.reqScreenshotRes = action.payload.data;
                }
            })
            .addCase(requestPortalScreenshot.rejected, (state: any, action: any) => {
                state.reqLoading = false;
                state.reqError = true;
                state.reqScreenshotRes = {};
                state.errorObj = action.error;
            });
    },
});
export const { setReqScreenshotRes, setLastLatestScreenshotRes } = screenshotSlice.actions;
export default screenshotSlice.reducer;
