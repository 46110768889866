import React from 'react';

const NoRecords = (props: any) => {
    return (
        <React.Fragment>
            <div className="empty-records text-center heading2-primary w-100 m-auto">{props.message}</div>
        </React.Fragment>
    );
};

export default NoRecords;
