import { createSlice, PayloadAction } from '@reduxjs/toolkit';
interface SideBar {
    unfoldable: boolean | undefined;
}

const initialState = { unfoldable: false } as SideBar;

const sidebarSlice = createSlice({
    name: 'sidebar',
    initialState,
    reducers: {
        showUnfoldableView: (state, action: PayloadAction<boolean | undefined>) => {
            state.unfoldable = action.payload;
        },
    },
});

export const { showUnfoldableView } = sidebarSlice.actions;
export default sidebarSlice.reducer;
