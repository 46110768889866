// Built-in
import React, { FormEvent, useEffect, useState } from 'react';
// External libraries
import { CButton, CCol, CContainer, CForm, CFormGroup, CImg, CInput, CLabel, CRow } from '@coreui/react';
import { Link, useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
// Internal project-specific
import './styles.scss';
import { ErrorBoundary, Toast, ToastProps, When } from '../../../Components';
import { TOAST_FADEOUT } from '../../../utils';

const ForgotPassword = () => {
    const history = useHistory();
    const initialError = {
        email: '',
    };
    const [email, setEmail] = useState('');
    const [error, setError] = useState(initialError);
    const [isSubmit, setIsSubmit] = useState(false);
    const [toastVals, setToastVals] = useState({ type: '', message: '', msgHeader: '', show: false });

    const handleSubmit = (e: FormEvent<any>) => {
        setToastVals({ type: '', message: '', msgHeader: '', show: false });
        e.preventDefault();
        setIsSubmit(true);
        if (validateEmail(email)) {
            Auth.forgotPassword(email)
                .then((user) => {
                    if (user && user.hasOwnProperty('CodeDeliveryDetails')) {
                        localStorage.setItem('fpemail', email);
                        setToastVals({
                            type: 'success',
                            message: 'Verification code sent on registered email',
                            msgHeader: 'Success!',
                            show: true,
                        });
                        setTimeout(() => {
                            history.push('/completeFP');
                            setToastVals({ type: '', message: '', msgHeader: '', show: false } as ToastProps);
                        }, TOAST_FADEOUT);
                    }
                })
                .catch((error) => {
                    setToastVals({
                        type: 'error',
                        message: error.message,
                        msgHeader: 'Forgot Password Error',
                        show: true,
                    });
                    setIsSubmit(false);
                    setTimeout(() => {
                        setToastVals({ type: '', message: '', msgHeader: '', show: false } as ToastProps);
                    }, TOAST_FADEOUT);
                });
        }
    };

    const validateEmail = (email: string) => {
        const emailRegex =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const errObj = { ...initialError };
        if (!email.trim().length) {
            errObj.email = 'Email required';
        } else if (!emailRegex.test(email.trim())) {
            errObj.email = 'Please enter a valid email address';
        } else {
            errObj.email = '';
        }

        setError(errObj);
        return !errObj.email.length;
    };

    useEffect(() => {
        localStorage.removeItem('fpemail');
        return () => setEmail('');
    }, []);

    return (
        <ErrorBoundary>
            <CContainer className="forgot-password">
                <div className="justify-content-start">
                    <CImg className="logo" src={'logo/large-without-sub.svg'} alt="Noro" />
                </div>
                <CRow className="justify-content-center align-items-center">
                    <CForm className="forgot-form" onSubmit={handleSubmit}>
                        <h1 className="white text-center">Forgot password?</h1>
                        <p className="dark-grey text-center">Don't worry we will help you out</p>
                        <CFormGroup className="custom-input lg">
                            <CLabel className="body1-secondary white-important">Email</CLabel>
                            <CInput
                                type="email"
                                label="Email"
                                placeholder="Enter your email"
                                name="email"
                                value={email}
                                onChange={(e: any) => setEmail(e.target.value)}
                            />
                            <When isTrue={error.email.length !== 0}>
                                <CLabel className="body1-secondary danger-important">{error.email}</CLabel>
                            </When>
                        </CFormGroup>

                        <CRow>
                            <CButton
                                type="submit"
                                className={`btn-custom btn-primary x-lg btn-width ${isSubmit ? 'disabled' : ''}`}
                            >
                                Forgot Password
                            </CButton>
                        </CRow>
                        <CRow>
                            <CCol className="text-right my-3 text-white">
                                Back to{' '}
                                <Link className={`btn-link back-to-login ${isSubmit ? 'disabled' : ''}`} to={'/login'}>
                                    Sign in
                                </Link>
                            </CCol>
                        </CRow>
                    </CForm>
                </CRow>
            </CContainer>
            <Toast toastVals={toastVals}></Toast>
        </ErrorBoundary>
    );
};

export default ForgotPassword;
