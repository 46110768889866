import React from 'react';
import { useCheckLoggedInUser } from '../hooks/useCheckLoggedInUser';

interface WrapperProps {
    children: React.ReactNode;
}

const RoutesWrapper = (props: WrapperProps) => {
    useCheckLoggedInUser();

    return <div>{props.children}</div>;
};

export default RoutesWrapper;
