import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { addOrgAdminByOrgIdAPIV2 } from '../../api/addOrgAdminByOrgIdAPIV2';
import { reinviteOrgAdminByOrgIdAPI } from './../../api/reinviteOrgAdminByOrgIdAPI';
import { deleteAdminUserAPI } from '../../api/deleteAdminUserAPI';
import { getAllOrgAdminsByOrgIdAPI } from '../../api/getAllOrgAdminsByOrgIdAPI';
import { getOrgAdminByOrgIdAPI } from '../../api/getOrgAdminByOrgIdAPI';
import { updateOrgAdminByOrgIdAPI } from '../../api/updateOrgAdminByOrgIdAPI';
import { GENERIC_ERROR_MESSAGE } from '../../utils';
import { OrgAdminModel, ReinviteOrgAdminModel } from '../model/orgAdminModel';

const initialState = {
    allOrgAdmins: { data: [] as OrgAdminModel[], pager: {} },
    loading: false,
    error: false,
    errorObj: {} as any,
    selectedOrgId: '',
    orgAdmin: {} as OrgAdminModel,
    orgAdminErrorObj: {} as any,
    orgAdminError: false,
    updatedOrgAdmin: {} as OrgAdminModel,
    updatedOrgLoading: false,
    updatedOrgError: false,
    updatedOrgErrorObj: {} as any,
    deletedAdminUser: {} as any,
    loadingDeletedAdmin: false,
    errorDeletedAdmin: false,
    errorDeletedAdminObj: {} as any,
    reinviteLoading: false,
    reinviteError: false,
    reinviteErrorObj: {} as any,
    reinviteOrgAdmin: {} as ReinviteOrgAdminModel,
};

export const getAllOrgAdminsByOrgId = createAsyncThunk('orgAdmin/getAllOrgAdminsByOrgId', async (obj: any) => {
    const response = await getAllOrgAdminsByOrgIdAPI(obj);
    return response.data;
});

export const addOrgAdminByOrgId = createAsyncThunk(
    'orgAdmin/addOrgAdminByOrgId',
    async (obj: any, { rejectWithValue }) => {
        try {
            return await addOrgAdminByOrgIdAPIV2(obj);
        } catch (err: any) {
            if (!err.response) {
                throw err;
            }
            return rejectWithValue(err.response.data);
        }
    },
);

export const reinviteOrgAdminByOrgId = createAsyncThunk(
    'orgAdmin/reinviteOrgAdminByOrgId',
    async (obj: any, { rejectWithValue }) => {
        try {
            return await reinviteOrgAdminByOrgIdAPI(obj);
        } catch (err: any) {
            if (!err.response) {
                throw err;
            }
            return rejectWithValue(err.response.data);
        }
    },
);

export const deleteAdminUser = createAsyncThunk('orgAdmin/deleteAdminUser', async (obj: any, { rejectWithValue }) => {
    try {
        return await deleteAdminUserAPI(obj);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data);
    }
});

export const updateOrgAdminByOrgId = createAsyncThunk(
    'orgAdmin/updateOrgAdminByOrgId',
    async (obj: any, { rejectWithValue }) => {
        try {
            delete obj.updatedOrgAdmin.org_id;
            delete obj.updatedOrgAdmin.role;
            delete obj.updatedOrgAdmin.email;
            delete obj.updatedOrgAdmin.pk;
            delete obj.updatedOrgAdmin.sk;
            delete obj.updatedOrgAdmin.created_on;
            return await updateOrgAdminByOrgIdAPI(obj);
        } catch (err: any) {
            if (!err.response) {
                throw err;
            }
            return rejectWithValue(err.response);
        }
    },
);

export const getOrgAdminByOrgId = createAsyncThunk(
    'orgAdmin/getOrgAdminByOrgId',
    async (obj: any, { rejectWithValue }) => {
        try {
            return await getOrgAdminByOrgIdAPI(obj);
        } catch (err: any) {
            if (!err.response) {
                throw err;
            }
            return rejectWithValue(err.response);
        }
    },
);

const orgAdminSlice = createSlice({
    name: 'orgAdmin',
    initialState,
    reducers: {
        setResetOrgAdmin(state, action) {
            state.orgAdmin = action.payload;
            if (JSON.stringify(action.payload) === '{}') {
                localStorage.removeItem('orgAdmin');
            }
        },
        setUpdateOrgAdminEmpty(state) {
            state.updatedOrgAdmin = {} as OrgAdminModel;
            state.updatedOrgLoading = false;
            state.updatedOrgError = false;
            state.updatedOrgErrorObj = {} as any;
        },
        setDeletedOrgAdminEmpty(state) {
            state.loadingDeletedAdmin = false;
            state.errorDeletedAdmin = false;
            state.errorDeletedAdminObj = {} as any;
            state.deletedAdminUser = {} as any;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllOrgAdminsByOrgId.pending, (state) => {
                // action is inferred correctly here if using TS
                state.loading = true;
                state.error = false;
                state.orgAdmin = {} as OrgAdminModel;
            })
            .addCase(getAllOrgAdminsByOrgId.fulfilled, (state, action) => {
                state.loading = false;
                state.error = false;
                const tempObj = JSON.parse(JSON.stringify(action.payload));
                if (tempObj && tempObj.data && tempObj.data.length > 0) {
                    tempObj.data.forEach((item: any, index: number) => {
                        if (item && Object.keys(item).length > 1 && !item.hasOwnProperty('userAccountStatus')) {
                            item.userAccountStatus = '';
                        }
                        if (!item.created_on) {
                            item.created_on = '';
                        }
                        item.index = index;
                    });
                }
                state.allOrgAdmins = JSON.parse(JSON.stringify(tempObj));
                state.orgAdmin = {} as OrgAdminModel;
            })
            .addCase(getAllOrgAdminsByOrgId.rejected, (state) => {
                state.loading = false;
                state.error = true;
                state.orgAdmin = {} as OrgAdminModel;
            })
            .addCase(addOrgAdminByOrgId.pending, (state) => {
                // action is inferred correctly here if using TS
                state.loading = true;
                state.error = false;
            })
            .addCase(addOrgAdminByOrgId.fulfilled, (state, action) => {
                state.loading = false;
                state.error = false;
                state.orgAdmin = action.payload.data;
            })
            .addCase(addOrgAdminByOrgId.rejected, (state, action: any) => {
                state.loading = false;
                state.error = true;
                state.errorObj = { message: action?.payload?.message };
            })
            .addCase(reinviteOrgAdminByOrgId.pending, (state) => {
                // action is inferred correctly here if using TS
                state.reinviteLoading = true;
                state.reinviteError = false;
            })
            .addCase(reinviteOrgAdminByOrgId.fulfilled, (state, action) => {
                state.reinviteLoading = false;
                state.reinviteError = false;
                state.reinviteOrgAdmin = action.payload.data;
            })
            .addCase(reinviteOrgAdminByOrgId.rejected, (state, action: any) => {
                state.reinviteLoading = false;
                state.reinviteError = true;
                state.reinviteErrorObj = { message: action?.payload?.message };
            })
            .addCase(updateOrgAdminByOrgId.pending, (state) => {
                // action is inferred correctly here if using TS
                state.updatedOrgLoading = true;
                state.updatedOrgError = false;
            })
            .addCase(updateOrgAdminByOrgId.fulfilled, (state, action) => {
                state.updatedOrgLoading = false;
                state.updatedOrgError = false;
                state.updatedOrgErrorObj = {} as any;
                state.updatedOrgAdmin = action.payload.data;
            })
            .addCase(updateOrgAdminByOrgId.rejected, (state, action: any) => {
                state.updatedOrgLoading = false;
                state.updatedOrgError = true;
                state.updatedOrgErrorObj = { message: action.payload.data.message ?? GENERIC_ERROR_MESSAGE };
            })
            .addCase(getOrgAdminByOrgId.pending, (state) => {
                // action is inferred correctly here if using TS
                state.loading = true;
                state.orgAdminError = false;
                state.orgAdminErrorObj = {};
            })
            .addCase(getOrgAdminByOrgId.fulfilled, (state, action) => {
                state.orgAdminError = false;
                state.orgAdminErrorObj = {};
                state.orgAdmin = action.payload.data;
                state.loading = false;
            })
            .addCase(getOrgAdminByOrgId.rejected, (state, action: any) => {
                state.loading = false;
                let msg = GENERIC_ERROR_MESSAGE;
                if (
                    action.payload &&
                    action.payload.data &&
                    action.payload.data.message &&
                    action.payload.data.message.includes('OrgAdmin not found under')
                ) {
                    msg = 'Organization admin not found.';
                }
                state.orgAdminErrorObj = { message: msg ?? GENERIC_ERROR_MESSAGE };
                state.orgAdminError = true;
            })
            .addCase(deleteAdminUser.pending, (state) => {
                // action is inferred correctly here if using TS
                state.loadingDeletedAdmin = true;
                state.errorDeletedAdmin = false;
            })
            .addCase(deleteAdminUser.fulfilled, (state, action) => {
                state.errorDeletedAdmin = false;
                state.deletedAdminUser = action.payload.data;
                state.loadingDeletedAdmin = false;
            })
            .addCase(deleteAdminUser.rejected, (state, action: any) => {
                state.errorDeletedAdminObj = { message: action?.payload?.message };
                state.loadingDeletedAdmin = false;
                state.errorDeletedAdmin = true;
                state.deletedAdminUser = {} as any;
            });
    },
});
export const { setResetOrgAdmin, setUpdateOrgAdminEmpty, setDeletedOrgAdminEmpty } = orgAdminSlice.actions;
export default orgAdminSlice.reducer;
