import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './scss/style.scss';
import { persistor, store } from './redux-toolkit/store/configure';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import ForgotPassword from './views/pages/forgot-password/ForgotPassword';
import CompleteNewLogin from './views/pages/complete_new_login/CompleteNewLogin';
import { AppServiceUpdater } from './Components';
import CompleteForgotPassword from './views/pages/complete-forgot-password/CompleteForgotPassword';
import InjectAxiosInterceptors from './api/InjectAxiosInterceptors';
import { PersistGate } from 'redux-persist/integration/react';
import { RoutesWrapper } from './utils';
import CompleteTempAccessLogin from './views/pages/complete-temp-access-login/CompleteTempAccessLogin';
import { AblyClientProvider } from 'Provider/AblyClientProvider';
import { AuthProvider } from 'Provider/AuthProvider';

const loading = (
    <div className="pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
);

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

const App = () => {
    return (
        <React.StrictMode>
            <I18nextProvider i18n={i18n}>
                <Provider store={store}>
                    <PersistGate loading={null} persistor={persistor}>
                        <AuthProvider>
                            <BrowserRouter>
                                <React.Suspense fallback={loading}>
                                    <InjectAxiosInterceptors />
                                    <RoutesWrapper>
                                        <Switch>
                                            <Route exact path="/login" render={(props: any) => <Login {...props} />} />
                                            <Route
                                                exact
                                                path="/forgotPassword"
                                                render={(props: any) => <ForgotPassword {...props} />}
                                            />
                                            <Route
                                                exact
                                                path="/completeFP"
                                                render={(props: any) => <CompleteForgotPassword {...props} />}
                                            />
                                            <Route
                                                exact
                                                path="/register"
                                                render={(props: any) => <Register {...props} />}
                                            />
                                            <Route
                                                exact
                                                path="/complete-signup"
                                                render={(props: any) => <CompleteNewLogin {...props} />}
                                            />

                                            <Route exact path="/404" render={(props: any) => <Page404 {...props} />} />
                                            <Route exact path="/500" render={(props: any) => <Page500 {...props} />} />
                                            <Route
                                                exact
                                                path="/completeLogin"
                                                render={(props: any) => <CompleteTempAccessLogin {...props} />}
                                            />
                                            <AblyClientProvider>
                                                <Route path="/" render={(props: any) => <TheLayout {...props} />} />
                                            </AblyClientProvider>
                                        </Switch>
                                    </RoutesWrapper>
                                </React.Suspense>
                            </BrowserRouter>
                        </AuthProvider>
                    </PersistGate>
                </Provider>
            </I18nextProvider>
            <AppServiceUpdater />
        </React.StrictMode>
    );
};

export default App;
