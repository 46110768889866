import { apiPost } from './axios';
import { ENDPOINT_DEV } from './configs';

interface Activity {
    orgId: string;
    portalId: string;
    activityLog: string;
    isAutoUpdate?: boolean;
    activityName?: string;
}
export const saveActivityListByPortalIdAPI = async ({
    orgId,
    portalId,
    activityLog,
    isAutoUpdate,
    activityName,
}: Activity) => {
    return await apiPost(ENDPOINT_DEV + `organization/${orgId}/portal/${portalId}/activity-logs`, {
        isAutoUpdate: isAutoUpdate,
        activityLog: activityLog,
        activityName: activityName,
    });
};
