import { createContext, useContext, useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
import useAuthListener from '../hooks/useAuthListener';
import { AWS_EXPORTS } from '../api/configs';
import Amplify from 'aws-amplify';

const AuthStateContext = createContext(null);

function AuthProvider({ children }) {
    // const dispatch = useDispatch();
    // const { portal_id } = useSelector((state) => state?.portal?.portal);
    // const { message, channel } = useAblyChannelHook();
    useAuthListener();

    useEffect(() => {
        Amplify.configure(AWS_EXPORTS);
    }, []);

    return <AuthStateContext.Provider value={{}}>{children}</AuthStateContext.Provider>;
}

function useAuthStateContext() {
    const context = useContext(AuthStateContext);
    if (!context) {
        throw new Error('useAuthStateContext must be used within the AuthStateContext');
    }
    return context;
}

export { AuthProvider, useAuthStateContext };
