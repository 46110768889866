import CIcon from '@coreui/icons-react';
import {
    CButton,
    CContainer,
    CForm,
    CFormGroup,
    CImg,
    CInput,
    CInputGroup,
    CInputGroupPrepend,
    CInputGroupText,
    CLabel,
    CRow,
    CSpinner,
} from '@coreui/react';
import { Auth } from 'aws-amplify';
import React, { useState, useEffect } from 'react';
import { ErrorBoundary, Toast, ToastProps } from '../../../Components';
import { useHistory } from 'react-router-dom';
import '../login/styles.scss';
import { TOAST_FADEOUT } from '../../../utils';

const CompleteForgotPassword = () => {
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [code, setCode] = useState('');
    const [disableAll, setDisableAll] = useState(false);
    const [authLoading, setAuthLoading] = useState(false);
    const [toastVals, setToastVals] = useState({ type: '', message: '', msgHeader: '', show: false } as ToastProps);
    const history = useHistory();
    const passwordRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[-+_!@#$%^&*.,?]).+$');

    useEffect(() => {
        const email = localStorage.getItem('fpemail');
        if (email) {
            setEmail(email);
        } else {
            history.push('/login');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const completeSignUp = () => {
        setAuthLoading(true);
        setDisableAll(true);
        Auth.forgotPasswordSubmit(email, code, password)
            .then((res) => {
                if (res && typeof res === 'string' && res === 'SUCCESS') {
                    localStorage.removeItem('fpemail');
                    setToastVals({
                        type: 'success',
                        message: 'Password reset completed. You will be redirected to login screen in few seconds.',
                        msgHeader: 'Success!',
                        show: true,
                    });
                    setTimeout(() => {
                        setToastVals({ type: '', message: '', msgHeader: '', show: false } as ToastProps);
                        history.push('/login');
                    }, TOAST_FADEOUT);
                }
            })
            .catch((err: any) => {
                setToastVals({
                    type: 'error',
                    message: err.message,
                    msgHeader: 'Error!',
                    show: true,
                });
                setTimeout(() => {
                    setToastVals({ type: '', message: '', msgHeader: '', show: false } as ToastProps);
                }, TOAST_FADEOUT);
                setDisableAll(false);
                setAuthLoading(false);
            });
    };
    return (
        <>
            <ErrorBoundary>
                <CContainer className="d-flex flex-column login">
                    <div className="justify-content-start">
                        <CImg className="logo" src={'logo/large-without-sub.svg'} alt="Noro" />
                    </div>
                    <CRow className="flex-grow-1 justify-content-center align-items-center">
                        <CForm className="complete-fp">
                            <h1 className="white text-center">Set new password</h1>
                            <CFormGroup className="custom-input lg bottom-margin-30">
                                <CLabel className="body4-secondary white-important">Verification Code</CLabel>
                                <CInputGroup className="mb-3">
                                    <CInputGroupPrepend>
                                        <CInputGroupText>
                                            <CIcon name="cil-user" />
                                        </CInputGroupText>
                                    </CInputGroupPrepend>
                                    <CInput
                                        type="number"
                                        disabled={disableAll}
                                        name="code"
                                        onChange={(e: any) => {
                                            setCode(e.target.value);
                                        }}
                                        placeholder="Verification Code"
                                    />
                                </CInputGroup>
                            </CFormGroup>
                            <CFormGroup className="custom-input lg bottom-margin-15">
                                <CLabel className="body4-secondary white-important">New Password</CLabel>
                                <CInputGroup className="mb-2">
                                    <CInputGroupPrepend>
                                        <CInputGroupText>
                                            <CIcon name="cil-lock-locked" />
                                        </CInputGroupText>
                                    </CInputGroupPrepend>
                                    <CInput
                                        disabled={disableAll}
                                        type="password"
                                        name="password"
                                        onChange={(e: any) => {
                                            setPassword(e.target.value);
                                        }}
                                        placeholder="Define new password"
                                    />
                                </CInputGroup>
                            </CFormGroup>

                            <div className="password-policy-container mb-4">
                                <div className="section">
                                    <div className="dot"></div>
                                    <div className="policy-text">Eight characters minimum</div>
                                </div>
                                <div className="section">
                                    <div className="dot"></div>
                                    <div className="policy-text">One lowercase character</div>
                                </div>
                                <div className="section">
                                    <div className="dot"></div>
                                    <div className="policy-text">One uppercase character</div>
                                </div>
                                <div className="section">
                                    <div className="dot"></div>
                                    <div className="policy-text">One special character</div>
                                </div>
                                <div className="section">
                                    <div className="dot"></div>
                                    <div className="policy-text">One number</div>
                                </div>
                            </div>

                            <CFormGroup className="custom-input lg bottom-margin-30">
                                <CButton
                                    className="btn-custom btn-primary x-lg btn-width"
                                    disabled={
                                        password === '' ||
                                        password === null ||
                                        password === undefined ||
                                        password.length < 8 ||
                                        disableAll ||
                                        !passwordRegex.test(password)
                                    }
                                    onClick={() => {
                                        completeSignUp();
                                    }}
                                >
                                    {authLoading ? (
                                        <>
                                            <CSpinner grow={true} size="sm" />
                                        </>
                                    ) : (
                                        <>Complete Registration</>
                                    )}
                                </CButton>
                            </CFormGroup>
                        </CForm>
                    </CRow>
                    <Toast toastVals={toastVals}></Toast>
                </CContainer>
            </ErrorBoundary>
        </>
    );
};

export default CompleteForgotPassword;
