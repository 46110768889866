// Built-in
import React from 'react';

// External libraries
import { CToast, CToastBody, CToaster, CToastHeader } from '@coreui/react';
import DoneIcon from '@mui/icons-material/Done';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

const Toast = (props: any) => {
    return (
        <>
            <CToaster position="top-center" className="custom-toast">
                <CToast show={props.toastVals.show} autohide={5000} fade={true} className={props.toastVals.type}>
                    <CToastHeader closeButton={false} className={props.toastVals.type}>
                        {props.toastVals.msgHeader}
                    </CToastHeader>
                    <CToastBody className="d-flex justify-content-between">
                        <div className="msg">{props.toastVals.message}</div>
                        <div className={`icon ${props.toastVals.type}`}>
                            {props.toastVals.type === 'error' && <PriorityHighIcon></PriorityHighIcon>}
                            {props.toastVals.type === 'warning' && <PriorityHighIcon></PriorityHighIcon>}
                            {props.toastVals.type === 'info' && <DoneIcon></DoneIcon>}
                            {props.toastVals.type === 'success' && <DoneIcon></DoneIcon>}
                        </div>
                    </CToastBody>
                </CToast>
            </CToaster>
        </>
    );
};

export default React.memo(Toast);
