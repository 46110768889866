import { apiPost } from './axios';
import { ENDPOINT_DEV } from './configs';

import { getScreenshotByPortalModel } from './../redux-toolkit/model/screenshotModel';

export const requestPortalScreenshotAPI = async ({ orgId, portalId }: getScreenshotByPortalModel) => {
    return await apiPost(ENDPOINT_DEV + `organization/${orgId}/portal/${portalId}/screen-shot`);
};
/* export const requestPortalScreenshotAPI = async ({ orgId, portalId }: getScreenshotByPortalModel) => {
    return await axios
        .get('https://run.mocky.io/v3/7e17fde3-d265-4b5c-9fa4-704e66f3e84f')
        .then((res) => {
            return res;
        })
        .catch((error) => {
            return error;
        });
};
 */
