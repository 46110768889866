import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    type: '',
    message: '',
    msgHeader: '',
    show: false,
};

const toastSlice = createSlice({
    name: 'toast',
    initialState,
    reducers: {
        setEmptyToast: (state) => {
            state.type = '';
            state.message = '';
            state.msgHeader = '';
            state.show = false;
        },
        setToast: (state, action) => {
            state.type = action.payload.type;
            state.message = action.payload.message;
            state.msgHeader = action.payload.msgHeader;
            state.show = !state.message ? false : action.payload.show;
        },
    },
});
export const { setEmptyToast, setToast } = toastSlice.actions;
export default toastSlice.reducer;
