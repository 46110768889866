import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { addConnectionTitleAPI } from '../../api/addConnectionTitleAPI';

const initialState = {
    connectionTitleLoading: false,
    connectionTitleError: false,
    connectionTitleErrorObj: {} as any,
    isConnectionTitleVisible: false,
    connectionTitleData: {} as any,
    notificationPreference: {} as any,
    connectionDuration: {} as any,
};

export const updateConnectionTitle = createAsyncThunk(
    'user/updateConnectionTitle',
    async (obj: any, { rejectWithValue }) => {
        try {
            return await addConnectionTitleAPI(obj);
        } catch (err: any) {
            if (!err.response) {
                throw err;
            }
            return rejectWithValue(err.response.data);
        }
    },
);

const connectionTitleSlice = createSlice({
    name: 'connectionTitle',
    initialState,
    reducers: {
        setConnectionTitleEmpty(state) {
            state.connectionTitleData = {} as any;
            state.connectionTitleLoading = false;
            state.connectionTitleError = false;
            state.connectionTitleErrorObj = {} as any;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(updateConnectionTitle.pending, (state) => {
                state.connectionTitleLoading = true;
                state.connectionTitleError = false;
            })
            .addCase(updateConnectionTitle.fulfilled, (state, action) => {
                state.connectionTitleData = action?.payload;
                state.isConnectionTitleVisible = action?.payload?.data?.data?.isConnectionTitleVisible;
                state.notificationPreference = action?.payload?.data?.data?.notificationPreference;
                state.connectionDuration = action?.payload?.data?.data?.connectionDuration;
                state.connectionTitleLoading = false;
                state.connectionTitleError = false;
            })
            .addCase(updateConnectionTitle.rejected, (state, action) => {
                state.connectionTitleLoading = false;
                state.connectionTitleError = true;
                state.connectionTitleErrorObj = action.payload;
            });
    },
});

export const { setConnectionTitleEmpty } = connectionTitleSlice.actions;

export default connectionTitleSlice.reducer;
