import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAllOrganizationsAPI } from '../../api/getAllOrganizationsAPI';
import { getOrganizationByOrgIdAPI } from '../../api/getOrganizationByOrgIdAPI';
import { updateOrganizationByOrgIdAPI } from '../../api/updateOrganizationByOrgIdAPI';
import { addOrganizationAPI } from '../../api/addOrganizationAPI';
import { OrganizationModel } from '../model/organizationModel';
import { getFeatureFlgsListByOrgIdAPI } from 'api/getFeatureFlgsListByOrgIdAPI';
import { getFeatureFlagObj } from 'utils/Utils';
import { getFeatureObj } from 'utils/getFeatureObj';
/* import { getDefaultAuthStorage } from '../../utils'; */

const initialState = {
    allOrganization: { data: [] as OrganizationModel[], pager: {} },
    loading: true,
    error: false,
    errorObj: {} as any,
    selectedOrgId: '',
    organization: {} as OrganizationModel,
    updatedOrganization: {} as OrganizationModel,
    addOrganizationLoading: false,
    addOrganizationError: false,
    addOrganizationErrorObj: {} as any,
    addOrganizationObj: {} as any,
    featureFlags: {},
    orgLoading: false,
};

export const getAllOrganizations = createAsyncThunk('organization/getAllOrganizations', async (limit?: number) => {
    const response = await getAllOrganizationsAPI(limit);
    return response.data;
});

export const getOrganizationByOrgId = createAsyncThunk('organization/getOrganizationByOrgId', async (orgId: string) => {
    return await getOrganizationByOrgIdAPI(orgId);
});

export const updateOrganizationByOrgId = createAsyncThunk(
    'organization/updateOrganizationByOrgId',
    async (org: any) => {
        delete org.updatedOrg.pk;
        delete org.updatedOrg.sk;
        delete org.updatedOrg.org_id;
        delete org.updatedOrg.updated_on;
        delete org.updatedOrg.created_on;
        return await updateOrganizationByOrgIdAPI(org);
    },
);

export const addOrganization = createAsyncThunk(
    'organization/addOrganization',
    async (orgObj: any, { rejectWithValue }) => {
        try {
            return await addOrganizationAPI(orgObj);
        } catch (err: any) {
            if (!err.response) {
                throw err;
            }
            return rejectWithValue(err.response.data);
        }
    },
);

export const getFeatureFlgsListByOrgId = createAsyncThunk('organization/getOrgDetailAPI', async (orgId: string) => {
    const response = await getFeatureFlgsListByOrgIdAPI(orgId);
    return response.data;
});

const organizationSlice = createSlice({
    name: 'organization',
    initialState,
    reducers: {
        setSelectedOrganizationId: (state, action) => {
            state.selectedOrgId = action.payload;
        },
        setAddOrganizationEmpty(state) {
            state.addOrganizationObj = {} as any;
            state.addOrganizationLoading = false;
            state.addOrganizationError = false;
            state.addOrganizationErrorObj = {} as any;
        },
        setOrganizationEmpty: (state) => {
            state.organization = {} as OrganizationModel;
            state.featureFlags = {};
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllOrganizations.pending, (state) => {
                // action is inferred correctly here if using TS
                state.loading = true;
                state.error = false;
            })
            .addCase(getAllOrganizations.fulfilled, (state, action) => {
                state.loading = false;
                state.error = false;
                let newOrgList = {
                    data: [] as OrganizationModel[],
                    pager: action.payload.pager,
                };
                action.payload.data.forEach((item: any) => {
                    if (!item.org_id.includes('-bkp')) {
                        newOrgList.data.push(item);
                    }
                });

                state.allOrganization = newOrgList;
            })
            .addCase(getAllOrganizations.rejected, (state, action) => {
                state.loading = false;
                state.error = true;
                state.errorObj = action.error;
                state.allOrganization = { data: [] as OrganizationModel[], pager: {} };
            })
            .addCase(getOrganizationByOrgId.pending, (state) => {
                // action is inferred correctly here if using TS
                state.orgLoading = true;
                state.error = false;

                state.updatedOrganization = {} as OrganizationModel;
            })
            .addCase(getOrganizationByOrgId.fulfilled, (state, action) => {
                state.orgLoading = false;
                state.error = false;
                if (action.payload?.data) {
                    state.organization = action.payload.data as OrganizationModel;
                    state.featureFlags = getFeatureObj(action.payload.data);
                    state.updatedOrganization = {} as OrganizationModel;
                } else {
                    state.organization = {} as OrganizationModel;
                    state.updatedOrganization = {} as OrganizationModel;
                    state.error = true;
                }
            })
            .addCase(getOrganizationByOrgId.rejected, (state, action) => {
                state.orgLoading = false;
                state.error = true;
                state.errorObj = action.error;
                state.updatedOrganization = {} as OrganizationModel;
            })
            .addCase(updateOrganizationByOrgId.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(updateOrganizationByOrgId.fulfilled, (state, action) => {
                state.loading = false;
                state.error = false;
                if (action.payload['data']) {
                    state.updatedOrganization = action.payload.data as OrganizationModel;
                } else {
                    state.updatedOrganization = {} as OrganizationModel;
                    state.error = true;
                }
            })
            .addCase(updateOrganizationByOrgId.rejected, (state, action) => {
                state.loading = false;
                state.error = true;
                state.errorObj = action.error;
            })
            .addCase(addOrganization.pending, (state) => {
                // action is inferred correctly here if using TS
                state.addOrganizationLoading = true;
                state.addOrganizationError = false;
            })
            .addCase(addOrganization.fulfilled, (state, action) => {
                state.addOrganizationLoading = false;
                state.addOrganizationError = false;
                state.addOrganizationObj = action?.payload;
            })
            .addCase(addOrganization.rejected, (state, action: any) => {
                state.addOrganizationLoading = false;
                state.addOrganizationError = true;
                state.addOrganizationErrorObj = action?.payload;
            })
            .addCase(getFeatureFlgsListByOrgId.pending, (state) => {
                // action is inferred correctly here if using TS
                state.loading = true;
                state.error = false;
            })
            .addCase(getFeatureFlgsListByOrgId.fulfilled, (state, action) => {
                state.loading = false;
                state.error = false;
                if (action.payload?.featureFlags) {
                    state.featureFlags = getFeatureFlagObj(action.payload);
                }
            })
            .addCase(getFeatureFlgsListByOrgId.rejected, (state, action) => {
                state.loading = false;
                state.error = true;
                state.errorObj = action.error;
                state.featureFlags = {};
            });

        // You can chain calls, or have separate `builder.addCase()` lines each time
        /* .addCase(decrement, (state, action) => {}) */
        // You can match a range of action types
        /* .addMatcher(
            isRejectedAction,
            // `action` will be inferred as a RejectedAction due to isRejectedAction being defined as a type guard
            (state, action) => {}
          ) */
        // and provide a default case if no other handlers matched
        /* .addDefaultCase((state, action) => {}) */
    },
});

export const { setSelectedOrganizationId, setAddOrganizationEmpty, setOrganizationEmpty } = organizationSlice.actions;
export default organizationSlice.reducer;
