import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { addSAMLAPI } from '../../api/addSAMLAPI';
import { getSAMLByOrganizationIdAPI } from '../../api/getSAMLAPI';
import { updateSAMLByOrganizationIdAPI } from '../../api/updateSAMLAPI';

const initialState = {
    addedSAMLLoading: false,
    addedSAMLError: false,
    addedSAMLErrorObj: {} as any,
    addedSAMLSetting: {} as any,
    updatedOrgSetting: {} as any,
    updatedSAMLLoading: false,
    updatedSAMLError: false,
    updatedSAMLErrorObj: {} as any,
    getSAMLLoading: false,
    getSAMLError: false,
    getSAMLErrorObj: {} as any,
    organizationSetting: {} as any,
};

export const addSAML = createAsyncThunk('organization/addSAML', async (samlObj: any, { rejectWithValue }) => {
    try {
        return await addSAMLAPI(samlObj);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data);
    }
});

export const getSAMLByOrganizationId = createAsyncThunk(
    'organizationSetting/getSAMLByOrganizationId',
    async (orgId: string, { rejectWithValue }) => {
        try {
            return await getSAMLByOrganizationIdAPI(orgId);
        } catch (err: any) {
            if (!err.response) {
                throw err;
            }
            return rejectWithValue(err.response.data);
        }
    },
);

export const updateSAMLByOrganizationId = createAsyncThunk(
    'organizationSetting/updateSAMLByOrganizationId',
    async (samlObj: any, { rejectWithValue }) => {
        try {
            return await updateSAMLByOrganizationIdAPI(samlObj);
        } catch (err: any) {
            if (!err.response) {
                throw err;
            }
            return rejectWithValue(err.response.data);
        }
    },
);

const organizationSettingSlice = createSlice({
    name: 'orgSetting',
    initialState,
    reducers: {
        setUpdateOrgSettingEmpty(state) {
            state.updatedOrgSetting = {} as any;
            state.updatedSAMLLoading = false;
            state.updatedSAMLError = false;
            state.updatedSAMLErrorObj = {} as any;
        },
        setAddSAMLSettingEmpty(state) {
            state.addedSAMLSetting = {} as any;
            state.addedSAMLLoading = false;
            state.addedSAMLError = false;
            state.addedSAMLErrorObj = {} as any;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getSAMLByOrganizationId.pending, (state) => {
                state.getSAMLLoading = true;
                state.getSAMLError = false;
            })
            .addCase(getSAMLByOrganizationId.fulfilled, (state, action) => {
                state.getSAMLLoading = false;
                state.getSAMLError = false;
                state.organizationSetting = { data: action?.payload?.data?.data ?? action?.payload?.data };
                state.getSAMLErrorObj = {};
            })
            .addCase(getSAMLByOrganizationId.rejected, (state, action: any) => {
                state.getSAMLLoading = false;
                state.getSAMLError = true;
                state.getSAMLErrorObj = action?.payload;
                state.organizationSetting = {};
            })
            .addCase(updateSAMLByOrganizationId.pending, (state) => {
                state.updatedSAMLLoading = true;
                state.updatedSAMLError = false;
            })
            .addCase(updateSAMLByOrganizationId.fulfilled, (state, action) => {
                state.updatedSAMLLoading = false;
                state.updatedSAMLError = false;
                state.updatedOrgSetting = { data: action.payload.data.data };
                state.updatedSAMLErrorObj = {};
            })
            .addCase(updateSAMLByOrganizationId.rejected, (state, action: any) => {
                state.updatedSAMLLoading = false;
                state.updatedSAMLError = true;
                state.updatedSAMLErrorObj = action?.payload;
                state.updatedOrgSetting = {};
            })
            .addCase(addSAML.pending, (state) => {
                // action is inferred correctly here if using TS
                state.addedSAMLLoading = true;
                state.addedSAMLError = false;
            })
            .addCase(addSAML.fulfilled, (state, action) => {
                state.addedSAMLLoading = false;
                state.addedSAMLError = false;
                state.addedSAMLSetting = action?.payload;
            })
            .addCase(addSAML.rejected, (state, action: any) => {
                state.addedSAMLLoading = false;
                state.addedSAMLError = true;
                state.addedSAMLErrorObj = action?.payload;
            });
    },
});
export const { setUpdateOrgSettingEmpty, setAddSAMLSettingEmpty } = organizationSettingSlice.actions;
export default organizationSettingSlice.reducer;
