import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { addGroupByOrgIdAPI } from 'api/addGroupByOrgIdAPI';
import { deleteGroupByOrgIdGroupIdAPI } from 'api/deleteGroupByOrgIdGroupIdAPI';
import { getGroupListAPI } from 'api/getGroupListAPI';
import { getPortalsByGroupIdOrgIdAPI } from 'api/getPortalsByGroupIdOrgIdAPI';
import { updateGroupByOrgIdGroupIdAPI } from 'api/updateGroupByOrgIdAPI';

const initialState = {
    groupListLoading: false,
    error: false,
    errorObj: {} as any,
    groupList: [] as any,
    deleteLoading: false,
    deletedGroupId: {},
    group: {},
    portalsData: {} as { portals: []; group_id: string; org_id: string },
    loadingList: false,
    addLoading: false,
    addGroupSuccess: '',
    deletedGroupSuccess: '',
    updateGroupSuccess: '',
};

export const fetchGroupListByOrgId = createAsyncThunk('group/fetchGroupListByOrgId', async (orgId: string) => {
    return await getGroupListAPI(orgId);
});

export const deleteGroupByOrgIdGroupId = createAsyncThunk(
    'group/deleteGroupByOrgIdGroupId',
    async (groupObj: { orgId: string; groupId: string }) => {
        return await deleteGroupByOrgIdGroupIdAPI(groupObj);
    },
);

export const getPortalsByGroupIdOrgId = createAsyncThunk(
    'group/getPortalsByGroupIdOrgId',
    async (groupObj: { orgId: string; groupId: string }) => {
        return await getPortalsByGroupIdOrgIdAPI(groupObj);
    },
);

export const addGroupByOrgId = createAsyncThunk(
    'group/addGroupByOrgId',
    async (
        groupObj: {
            orgId: string;
            group: {
                groupName: string;
                isRestricted: boolean;
                description: string;
                isDefault: boolean;
            };
        },
        { rejectWithValue },
    ) => {
        try {
            return await addGroupByOrgIdAPI(groupObj);
        } catch (err: any) {
            if (!err.response) {
                throw err;
            }
            return rejectWithValue(err.response.data);
        }
    },
);

export const updateGroupByOrgIdGroupId = createAsyncThunk(
    'group/updateGroupByOrgIdGroupId',
    async (
        groupObj: {
            orgId: string;
            groupId: string;
            group: {
                groupName: string;
                isRestricted: boolean;
                description: string;
                isDefault: boolean;
            };
        },
        { rejectWithValue },
    ) => {
        try {
            return await updateGroupByOrgIdGroupIdAPI(groupObj);
        } catch (err: any) {
            if (!err.response) {
                throw err;
            }
            return rejectWithValue(err.response.data);
        }
    },
);

const groupSlice = createSlice({
    name: 'group',
    initialState,
    reducers: {
        setEmptyportalsListByGrpId: (state) => {
            state.portalsData = {} as any;
        },
        resetMessages: (state) => {
            state.addGroupSuccess = '';
            state.deletedGroupSuccess = '';
            state.updateGroupSuccess = '';
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchGroupListByOrgId.pending, (state) => {
                state.groupListLoading = true;
            })
            .addCase(fetchGroupListByOrgId.fulfilled, (state, action) => {
                state.groupListLoading = false;
                state.error = false;
                state.groupList = action?.payload?.data?.data;
            })
            .addCase(fetchGroupListByOrgId.rejected, (state, action) => {
                state.groupListLoading = false;
                state.error = true;
                state.errorObj = action.error;
            })
            .addCase(deleteGroupByOrgIdGroupId.pending, (state) => {
                state.deleteLoading = true;
                state.error = false;
                state.deletedGroupId = {};
            })
            .addCase(deleteGroupByOrgIdGroupId.fulfilled, (state, action) => {
                state.deleteLoading = false;
                state.error = false;
                state.groupList = state.groupList.filter((x: any) => x.id !== action.payload);
                state.deletedGroupId = action.payload?.data?.groupId;
                if (action.payload?.data?.deleted) {
                    state.deletedGroupSuccess = action.payload.data?.message;
                }
            })
            .addCase(deleteGroupByOrgIdGroupId.rejected, (state, action) => {
                state.deleteLoading = false;
                state.error = true;
                state.errorObj = action.error;
                state.deletedGroupId = {};
                state.deletedGroupSuccess = '';
            })
            .addCase(addGroupByOrgId.pending, (state) => {
                // action is inferred correctly here if using TS
                state.addLoading = true;
                state.error = false;
            })
            .addCase(addGroupByOrgId.fulfilled, (state, action) => {
                state.addLoading = false;
                state.error = false;
                state.errorObj = {};
                state.group = action.payload.data?.data;
                state.addGroupSuccess = action.payload.data?.message;
            })
            .addCase(addGroupByOrgId.rejected, (state, action) => {
                state.addLoading = false;
                state.error = true;
                state.errorObj = action.payload;
                state.addGroupSuccess = '';
            })
            .addCase(updateGroupByOrgIdGroupId.pending, (state) => {
                state.error = false;
                state.group = {};
            })
            .addCase(updateGroupByOrgIdGroupId.fulfilled, (state, action) => {
                state.error = false;
                state.errorObj = {};
                state.updateGroupSuccess = action.payload.data?.message;
            })
            .addCase(updateGroupByOrgIdGroupId.rejected, (state, action) => {
                state.error = true;
                state.errorObj = action.payload;
                state.updateGroupSuccess = '';
            })
            .addCase(getPortalsByGroupIdOrgId.pending, (state) => {
                state.loadingList = true;
                state.error = false;
                state.portalsData = {} as any;
            })
            .addCase(getPortalsByGroupIdOrgId.fulfilled, (state, action) => {
                state.loadingList = false;
                state.error = false;
                state.portalsData = action.payload.data;
            })
            .addCase(getPortalsByGroupIdOrgId.rejected, (state, action) => {
                state.loadingList = false;
                state.error = true;
                state.errorObj = action.error;
            });
    },
});

export const { setEmptyportalsListByGrpId, resetMessages } = groupSlice.actions;
export default groupSlice.reducer;
