// Built-in
import React from 'react';

// External libraries
import { CButton, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle } from '@coreui/react';
import CloseIcon from '@mui/icons-material/Close';

// Internal project-specific
import When from '../when/When';

interface Props {
    modalContent: {
        message: string | '';
        header: string | '';
        domContent?: any;
    };
    show: boolean | false;
    showCancelBtn: boolean | false;
    showOkBtn: boolean | true;
    okBtnText?: string | 'OK';
    closeModal?: () => void;
    onCancelButton?: () => void;
    onOkButton?: () => void;
    cancelBtnText?: string | 'Cancel';
    showHeaderCloseButton?: boolean | true;
    showHeaderText?: boolean | true;
    showHeader?: boolean | true;
}

const Modal = (props: Props) => {
    const closeModal = () => {
        if (props.closeModal) {
            props.closeModal();
        }
    };

    const onCancelButton = () => {
        if (props.onCancelButton) {
            props.onCancelButton();
        }
    };

    const onOkButton = () => {
        if (props.onOkButton) {
            props.onOkButton();
        }
    };

    return (
        <CModal
            className="custom-modal"
            show={props.show}
            centered
            onClose={() => {
                closeModal();
            }}
        >
            <When isTrue={props.showHeader}>
                <CModalHeader>
                    <When isTrue={props.showHeaderText}>
                        <CModalTitle className="heading1-primary">{props.modalContent.header}</CModalTitle>
                    </When>
                    <When isTrue={props.showHeaderCloseButton}>
                        <div className="modal-close-header">
                            <CloseIcon
                                sx={{ fontSize: '1rem' }}
                                onClick={() => {
                                    closeModal();
                                }}
                            ></CloseIcon>
                        </div>
                    </When>
                </CModalHeader>
            </When>
            <CModalBody>
                <When isTrue={props.modalContent.message !== ''}>
                    <span className="body1-primary">{props.modalContent.message}</span>
                </When>
                <When isTrue={props.modalContent.domContent}>
                    <>{props.modalContent.domContent}</>
                </When>
            </CModalBody>
            <CModalFooter className="mr-3 mb-3">
                <When isTrue={props.showOkBtn}>
                    <CButton
                        className="btn-custom btn-black md"
                        onClick={() => {
                            onOkButton();
                        }}
                    >
                        {props.okBtnText}
                    </CButton>
                </When>
                <When isTrue={props.showCancelBtn}>
                    <CButton
                        className="btn-custom btn-black-outlined md"
                        onClick={() => {
                            onCancelButton();
                        }}
                    >
                        {props.cancelBtnText ? props.cancelBtnText : 'Cancel'}
                    </CButton>
                </When>
            </CModalFooter>
        </CModal>
    );
};

export default Modal;
