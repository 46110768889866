import CIcon from '@coreui/icons-react';
import {
    CButton,
    CCard,
    CCardBody,
    CCardGroup,
    CCol,
    CContainer,
    CForm,
    CFormGroup,
    CInput,
    CInputGroup,
    CInputGroupPrepend,
    CInputGroupText,
    CRow,
} from '@coreui/react';
import { Auth } from 'aws-amplify';
import _ from 'lodash';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from '../../../redux-toolkit/reducer';
import { setResetUserCogn } from '../../../redux-toolkit/reducer/userReducer';
import './styles.scss';

const CompleteTempAccessLogin = () => {
    /* const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-\"!@#%&\/,><\':;|_~`])\S{8,99}$/; */
    const history = useHistory();
    const dispatch = useDispatch();
    /* const [, setValidPassword] = useState(false);
    const [inputError, setInputError] = useState(""); */
    const [email] = useState(localStorage.getItem('userEmail') !== undefined ? localStorage.getItem('userEmail') : '');
    const [reEnteredPassword, setReEnteredPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');

    const { userObj } = useSelector(
        ({ user }: RootState) => ({
            userObj: user.resetUserCognObj,
        }),
        _.isEqual,
    );

    const resetPassword = () => {
        Auth.completeNewPassword(
            userObj, // the Cognito User Object
            newPassword, // the new password
        )
            .then(() => {
                // at this time the user is logged in if no MFA required
                dispatch(setResetUserCogn({}));
                history.push({
                    pathname: '/login',
                });
            })
            .catch((e) => {
                // eslint-disable-next-line
                console.log(e);
            });
    };
    /* const checkPassword = (event: any) => {
        setValidPassword(true);
        if(event.target.name === "reEnteredPassword"){
            if(!regex.test(event.target.value)){
                setInputError("Re-Entered Password should be of minimum 8 characters including atleast one uppercase, numeric and synbol");
                setValidPassword(false);
                return;
            }
            else {
                setInputError("");
                setReEnteredPassword(event.target.value);
            }
        }
        if(event.target.name === "newPassword") {
            if(!regex.test(event.target.value)){
                setInputError("New Password should be of minimum 8 characters including atleast one uppercase, numeric and synbol");
                setValidPassword(false);
                return;
            }
            else {
                setInputError("");
                setNewPassword(event.target.value);
            }
        }
        setTimeout(() => {
            if(newPassword !== reEnteredPassword){
                setInputError("New password and Reneter password should be same");
                setValidPassword(false);
                return;
            }
            setInputError("");
            setValidPassword(true);    
        }, 0); 
    } */
    return (
        <div className="c-app c-default-layout flex-row align-items-center">
            <CContainer>
                <CRow className="justify-content-center">
                    <CCol md="6">
                        <CCardGroup>
                            <CCard className="p-4">
                                <CCardBody>
                                    <CForm>
                                        <h1>Complete New Password</h1>
                                        <p className="text-muted">
                                            Your new password should be different than the previous password
                                        </p>
                                        <CInputGroup className="mb-3">
                                            <CInputGroupPrepend>
                                                <CInputGroupText>
                                                    <CIcon name="cil-user" />
                                                </CInputGroupText>
                                            </CInputGroupPrepend>
                                            <CInput
                                                type="text"
                                                defaultValue={email ? email : ''}
                                                disabled
                                                placeholder="Username"
                                            />
                                        </CInputGroup>
                                        <CInputGroup className="mb-4">
                                            <CInputGroupPrepend>
                                                <CInputGroupText>
                                                    <CIcon name="cil-lock-locked" />
                                                </CInputGroupText>
                                            </CInputGroupPrepend>
                                            <CInput
                                                type="password"
                                                name="newPassword"
                                                onChange={(e: any) => {
                                                    setNewPassword(e.target.value);
                                                }}
                                                placeholder="Password"
                                            />
                                        </CInputGroup>
                                        <CInputGroup className="mb-4">
                                            <CInputGroupPrepend>
                                                <CInputGroupText>
                                                    <CIcon name="cil-lock-locked" />
                                                </CInputGroupText>
                                            </CInputGroupPrepend>
                                            <CInput
                                                type="password"
                                                name="reEnteredPassword"
                                                onChange={(e: any) => {
                                                    setReEnteredPassword(e.target.value);
                                                }}
                                                placeholder="Re-Enter Password"
                                            />
                                        </CInputGroup>
                                        <CRow>
                                            <CCol xs="6">
                                                <CFormGroup className="custom-input lg">
                                                    <CButton
                                                        className="btn-custom btn-primary btn-md"
                                                        disabled={
                                                            newPassword === '' ||
                                                            reEnteredPassword === '' ||
                                                            newPassword !== reEnteredPassword ||
                                                            newPassword.length < 8 ||
                                                            reEnteredPassword.length < 8
                                                        }
                                                        onClick={() => {
                                                            resetPassword();
                                                        }}
                                                    >
                                                        Reset Password
                                                    </CButton>
                                                </CFormGroup>
                                            </CCol>
                                        </CRow>
                                        {/* {<CRow>
                                            <CCol xs="6">
                                                <span>{inputError}</span>
                                            </CCol>
                                        </CRow>} */}
                                    </CForm>
                                </CCardBody>
                            </CCard>
                        </CCardGroup>
                    </CCol>
                </CRow>
            </CContainer>
        </div>
    );
};

export default CompleteTempAccessLogin;
