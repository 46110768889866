import { Auth } from 'aws-amplify';
import jwt_decode from 'jwt-decode';
import { persistor } from '../redux-toolkit/store/configure';

export const getFormatedOrgNames = (orgs: any) => {
    const newOrgs = [] as any;
    orgs.forEach((item: any) => {
        let strOrgId = '';
        if (item.org_id.includes('-')) {
            strOrgId = item.org_id.replace('-', ' ');
        } else if (item.org_id.includes('_')) {
            strOrgId = item.org_id.replace('_', ' ');
        } else {
            strOrgId = item.org_id;
        }
        item.orgName = strOrgId;
        newOrgs.push(item);
    });
    return newOrgs;
};

export const getDecodedToken = (encodedToken: string) => {
    return jwt_decode(encodedToken);
};

export const getDefaultAuthStorage = () => {
    const keepSignedIn = JSON.parse(localStorage.getItem('keepSignedIn') || '{}');
    return keepSignedIn ? localStorage : sessionStorage;
};

export const getAccessToken = () => {
    return validateAndReturnToken();
};

export const clearAuthStorage = (storage: Storage) => {
    storage.removeItem('accessToken');
    storage.removeItem('idToken');
    storage.removeItem('uDetails');
    storage.removeItem('refreshToken');
    storage.removeItem('accessTokenExpiration');
    storage.removeItem('clientId');
};

export const signOut = async () => {
    await Auth.signOut();
    clearCache();
    clearAuthStorage(localStorage);
    clearAuthStorage(sessionStorage);
    setTimeout(() => persistor.purge(), 200);
    localStorage.removeItem('wsConnectionEstablishedAt');
    localStorage.removeItem('keepSignedIn');
};

export const TOAST_FADEOUT = 5100;
export const GENERIC_ERROR_MESSAGE = 'Something went wrong, try after sometime!';
export const GENERIC_ERROR_MESSAGE_1 = 'Something went wrong. Please try again later.';

export const TOAST_TYPE = {
    SUCCESS: 'success',
    ERROR: 'error',
    INFO: 'info',
    WARNING: 'warning',
};
export const TOAST_MESSAGE_HEADER = {
    SUCCESS: 'Successful!',
    ERROR: 'Error!',
    INFO: 'Info!',
    WARNING: 'Warning!',
};

export const PORTAL_STATES = {
    DISCONNECTED: 'disconnected',
    OFFLINE: 'offline',
    IN_CALL: 'in-call',
    IN_MAINTENANCE: 'in-maintenance',
    ONLINE: 'online',
    ERROR: 'error',
    PENDING: 'pending',
    BOOKED: 'booked',
    ARCHIVED: 'archived',
};

export const PORTAL_STATES_STR = {
    DISCONNECTED: 'Disconnected',
    OFFLINE: 'Offline',
    IN_CALL: 'In-Call',
};

export const LOCAL_ENV_PORTAL_AGENT = '0.0.0';
export const LATEST_VERSION_NOT_INSTALLED = -1;

/*
 Rounds off the given num till the mentioned decimalPlaces
 e.g. If we pass arguments as (1.7851, 2) the result will be 1.79. decimalPlaces can be default 0 if not provided
*/

export const naiveRound = (num: number, decimalPlaces = 0) => {
    const p = Math.pow(10, decimalPlaces);
    return Math.round(num * p) / p;
};

/**
 * Validate the token expiration and generate a new one if expired and return the token
 * @returns ID token
 */
export const validateAndReturnToken = () => {
    const currentTimeStamp = Math.round(Date.now() / 1000);
    const storage = getDefaultAuthStorage();
    if (!localStorage.getItem('accessTokenExpiration')) {
        const token = storage.getItem('idToken') as any;
        const decodedToken: any = jwt_decode(token);
        localStorage.setItem('accessTokenExpiration', JSON.stringify(decodedToken.exp));
    }
    if (localStorage.getItem('accessTokenExpiration')) {
        const expiryDate = Number(localStorage.getItem('accessTokenExpiration'));
        if (currentTimeStamp > expiryDate - 5 * 60) {
            return getAndStoreUpdatedToken();
        } else {
            return storage.getItem('idToken');
        }
    }
};

export const getAndStoreUpdatedToken = async () => {
    // Auth.currentSession() checks if token is expired and refreshes with Cognito if needed automatically
    const session = await Auth.currentSession();
    localStorage.setItem('idToken', session.getIdToken().getJwtToken());
    localStorage.setItem('accessTokenExpiration', JSON.stringify(session.getIdToken().getExpiration()));
    return session.getAccessToken().getJwtToken();
};

export const DISPLAY_TYPE_LCD_LED = 'lcd_led';
export const DISPLAY_TYPE_PROJECTOR = 'projector';

export default function convertStringifiedJSON(jsonArray: any): any {
    // Parse the JSON array into a JavaScript array
    const arr = typeof jsonArray === 'object' ? jsonArray : JSON.parse(jsonArray);
    // Recursive function to process the elements
    function processElement(element: any): any {
        if (typeof element === 'string') {
            try {
                // Try to parse the string as JSON
                return JSON.parse(element);
            } catch (error) {
                // If parsing fails, return the original string
                return element;
            }
        } else if (Array.isArray(element)) {
            // If it's an array, recursively process each element
            return element.map(processElement);
        } else if (typeof element === 'object' && element !== null) {
            // If it's an object, recursively process each property
            for (const key in element) {
                if (element.hasOwnProperty(key)) {
                    element[key] = processElement(element[key]);
                }
            }
        }
        return element;
    }
    // Process the entire array and return the result
    return processElement(arr);
}

export const clearCache = async () => {
    var theCookies = document.cookie.split(';');
    for (var i = 1; i <= theCookies.length; i++) {
        var acookie = theCookies[i - 1];
        var cookieArr = acookie.split('=');
        document.cookie = cookieArr[0] + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    }

    // Get cache storage and clear cache storage
    window.caches.keys().then(function (names) {
        for (let name of names) {
            window.caches.delete(name);
        }
    });
};

interface FeatureFlag {
    feature: string;
    enabled: boolean;
}

interface FeatureFlagsInput {
    featureFlags?: FeatureFlag[];
}

export const getFeatureFlagObj = ({ featureFlags }: FeatureFlagsInput): Record<string, boolean> => {
    const featureObject: Record<string, boolean> = {};
    // Check if featureFlags is not undefined before iterating
    if (featureFlags) {
        featureFlags.forEach((flag) => {
            featureObject[flag.feature] = flag.enabled;
        });
    }
    return featureObject;
};

/**
 * This util function will help to know if the user has the desired Privilege or not
 * @param currentLoggedIn
 * @param testPriv
 * @returns true/false (boolean)
 */
export const getIfHasPrivilege = (currentLoggedIn: any, testPriv: String) => {
    const currentOrg = currentLoggedIn?.currentSelectedOrg?.org_id;
    const matchingOrgObj = currentLoggedIn?.orgsPrivileges.filter((item: any) => item.org_id === currentOrg)[0];

    if (
        matchingOrgObj &&
        matchingOrgObj?.privileges &&
        matchingOrgObj?.privileges.find((str: String) => str === testPriv)
    ) {
        return true;
    }
    return false;
};

export const statusTextMapping = {
    [PORTAL_STATES.ONLINE]: 'Available',
    [PORTAL_STATES.OFFLINE]: 'Offline',
    [PORTAL_STATES.PENDING]: 'Pending Activation',
    [PORTAL_STATES.IN_CALL]: 'In Connection',
    [PORTAL_STATES.IN_MAINTENANCE]: 'In Maintenance',
    [PORTAL_STATES.DISCONNECTED]: 'Disconnected',
    [PORTAL_STATES.ERROR]: 'Error',
    [PORTAL_STATES.BOOKED]: 'Booked',
    [PORTAL_STATES.ARCHIVED]: 'Archived',
};

export const getPortalSatus = (portalData: any) => {
    if (portalData && !!portalData.isArchived) {
        return PORTAL_STATES.ARCHIVED;
    } else if (
        portalData &&
        (('isActivated' in portalData && !portalData.isActivated) ||
            portalData.portalConnectionStatus?.toLowerCase() === PORTAL_STATES.PENDING) &&
        portalData.portalConnectionStatus?.toLowerCase() !== PORTAL_STATES.DISCONNECTED
    ) {
        return PORTAL_STATES.PENDING;
    } else if (
        portalData &&
        'is_approved' in portalData &&
        !portalData.is_approved &&
        portalData.createdOn < 1659695841000 &&
        !('isActivated' in portalData) &&
        portalData.portalConnectionStatus?.toLowerCase() !== PORTAL_STATES.DISCONNECTED
    ) {
        return PORTAL_STATES.PENDING;
    } else if (
        portalData &&
        (('isActivated' in portalData && portalData.isActivated) || portalData.is_approved) &&
        portalData.portalConnectionStatus?.toLowerCase() === PORTAL_STATES.IN_CALL
    ) {
        return PORTAL_STATES.IN_CALL;
    } else if (
        portalData &&
        (('isActivated' in portalData && portalData.isActivated) || portalData.is_approved) &&
        portalData.portalConnectionStatus?.toLowerCase() === PORTAL_STATES.ONLINE
    ) {
        return PORTAL_STATES.ONLINE;
    } else if (
        portalData &&
        (('isActivated' in portalData && portalData.isActivated) || portalData.is_approved) &&
        portalData.portalConnectionStatus?.toLowerCase() === PORTAL_STATES.OFFLINE
    ) {
        return PORTAL_STATES.OFFLINE;
    } else if (
        portalData &&
        (('isActivated' in portalData && portalData.isActivated) || portalData.is_approved) &&
        portalData.portalConnectionStatus?.toLowerCase() === PORTAL_STATES.IN_MAINTENANCE
    ) {
        return PORTAL_STATES.IN_MAINTENANCE;
    } else if (portalData && portalData.portalConnectionStatus?.toLowerCase() === PORTAL_STATES.ERROR) {
        return PORTAL_STATES.ERROR;
    } else if (portalData && portalData.portalConnectionStatus?.toLowerCase() === PORTAL_STATES.DISCONNECTED) {
        return PORTAL_STATES.DISCONNECTED;
    } else if (portalData.portalConnectionStatus?.toLowerCase() === PORTAL_STATES.BOOKED) {
        return PORTAL_STATES.BOOKED;
    }
};
