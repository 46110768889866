import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setupInterceptors } from './axios';

const InjectAxiosInterceptors = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    useEffect(() => {
        setupInterceptors(history, dispatch);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history]);

    // not rendering anything
    return null;
};
export default InjectAxiosInterceptors;
