import { apiPost, apiPut } from './axios';
import { ENDPOINT_DEV } from './configs';
import { ImagePathI, IOConfigModel } from '../redux-toolkit/model/IOConfigModel';

interface IOConfig {
    orgId: string;
    portalId: string;
    io: IOConfigModel;
    isAutoUpdate?: boolean;
    activityLog?: string[];
}

interface GetAllCameraPreviewImagesProps {
    orgId: string;
    portalId: string;
    imagePaths: ImagePathI[];
}

interface UpdateCameraPositionApiProps {
    orgId: string;
    portalId: string;
    newCameraPosition: {
        deviceId: string;
        newHorizontalPosition: number;
    }[];
}

export const ioConfigPostAPI = async ({ orgId, portalId, io, isAutoUpdate, activityLog }: IOConfig) => {
    return await apiPost(ENDPOINT_DEV + `organization/${orgId}/portal/${portalId}/command/iodevices`, {
        ...io,
        isAutoUpdate: isAutoUpdate,
        activityLog: activityLog,
    });
};

export const getAllCameraPreviewImages = async ({ orgId, portalId, imagePaths }: GetAllCameraPreviewImagesProps) => {
    return await apiPost(ENDPOINT_DEV + `organization/${orgId}/portal/${portalId}/get-cameras-preview`, {
        imagePaths,
    });
};

export const updateCameraPositionApi = async ({ orgId, portalId, newCameraPosition }: UpdateCameraPositionApiProps) => {
    return await apiPut(ENDPOINT_DEV + `organization/${orgId}/portal/${portalId}/update-camera-position`, {
        newCameraPosition,
    });
};
