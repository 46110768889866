import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getUserByTokenSignUpAPI } from '../../api/getUserByTokenSignUpAPI';
import { TokenDetails } from '../model/tokenDetails';
import { User } from '../model/User';

const initialState = {
    user: {} as User,
    loading: false,
    error: false,
    resetUserCognObj: {},
    loggedInUser: {} as any,
    extractedToken: {} as TokenDetails,
    errorObj: {} as any,
    isSignOutUser: false,
};

export const getUserByTokenSignUp = createAsyncThunk(
    'user/getUserByTokenSignUp',
    async (token: string, { rejectWithValue }) => {
        try {
            return await getUserByTokenSignUpAPI(token);
        } catch (err: any) {
            if (!err.response) {
                throw err;
            }
            return rejectWithValue(err.response.data);
        }
    },
);

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        // this is to just manipulate state object
        setResetUserCogn: (state, action) => {
            state.resetUserCognObj = action.payload;
        },
        setLoggedInUser: (state, action) => {
            if (action.payload && Object.keys(action.payload).length > 0) {
                state.isSignOutUser = false;
            } else if (
                (action.payload && Object.keys(action.payload).length === 0) ||
                !action.payload ||
                action.payload === null
            ) {
                state.isSignOutUser = true;
            }
            state.loggedInUser = action.payload;
        },
        resetExtractedToken: (state) => {
            state.extractedToken = {} as TokenDetails;
            state.errorObj = {} as any;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getUserByTokenSignUp.pending, (state) => {
                // action is inferred correctly here if using TS
                state.loading = true;
                state.error = false;
                state.extractedToken = {} as TokenDetails;
            })
            .addCase(getUserByTokenSignUp.fulfilled, (state, action) => {
                state.loading = false;
                state.error = false;
                state.extractedToken = action.payload.data;
            })
            .addCase(getUserByTokenSignUp.rejected, (state, action) => {
                state.loading = false;
                state.error = true;
                state.extractedToken = {} as TokenDetails;
                state.errorObj = action.payload;
            });
    },
});

export const { setResetUserCogn, setLoggedInUser, resetExtractedToken } = userSlice.actions;
export default userSlice.reducer;
