import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setCameraPreviewError } from 'redux-toolkit/reducer/ioConfigReducer';
import {} from 'redux-toolkit/reducer/portalReducer';

export default function useMultiCamConfigResponse({ message }) {
    const dispatch = useDispatch();

    useEffect(() => {
        const command = message?.data?.message || '';
        const commandData = message?.data?.data || {};
        if (command === 'multi-cam-config-response') {
            dispatch(setCameraPreviewError({ error: true, message: commandData.message }));
        }
    }, [message, dispatch]);
}
