import { apiPost } from './axios';
import { ENDPOINT_DEV } from './configs';
import { CameraConfigModel, ProjectorConfigModel } from '../redux-toolkit/model/settingsConfigModel';

interface orgIdPortalId {
    orgId: string;
    portalId: string;
}

interface cameraConfigInterface extends orgIdPortalId {
    cameraConfig: CameraConfigModel;
}

interface projectorConfigInterface extends orgIdPortalId {
    orientation: ProjectorConfigModel;
}

export const cameraConfigPostAPI = async ({ orgId, portalId, cameraConfig }: cameraConfigInterface) => {
    return await apiPost(ENDPOINT_DEV + `organization/${orgId}/portal/${portalId}/command/camera-config`, cameraConfig);
};

export const projectorConfigPostAPI = async ({ orgId, portalId, orientation }: projectorConfigInterface) => {
    return await apiPost(ENDPOINT_DEV + `organization/${orgId}/portal/${portalId}/command/display-config`, orientation);
};
