// Built-in
import React, { useEffect } from 'react';

// External libraries
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux-toolkit/reducer';
import isEqual from 'lodash/isEqual';

// Internal project-specific
import { setOnchangeLoader } from '../../redux-toolkit/reducer/portalReducer';
import './save-message.scss';
import When from '../when/When';

const SavedMessage = () => {
    const dispatch = useDispatch();
    const { isCompLoader } = useSelector(
        ({ portal }: RootState) => ({
            allPortals: portal.allPortals.data,
            isCompLoader: portal.isCompLoader,
        }),
        isEqual,
    );

    useEffect(() => {
        if (isCompLoader?.isChangeLoader) {
            setTimeout(() => {
                dispatch(setOnchangeLoader({ isChangeLoader: false, isSavedloader: true }));
            }, 2000);
        }
    }, [dispatch, isCompLoader?.isChangeLoader]);

    useEffect(() => {
        if (isCompLoader?.isSavedloader) {
            setTimeout(() => {
                dispatch(setOnchangeLoader({ isChangeLoader: false, isSavedloader: false }));
            }, 3000);
        }
    }, [dispatch, isCompLoader?.isSavedloader]);

    return (
        <React.Fragment>
            <When isTrue={isCompLoader?.isChangeLoader || isCompLoader?.isSavedloader}>
                <div className="d-flex justify-content-between">
                    <div className="msg-wrapper">
                        {isCompLoader?.isChangeLoader && <span>Saving....</span>}
                        {isCompLoader?.isSavedloader && <span>All changes are saved</span>}
                    </div>
                </div>
            </When>
        </React.Fragment>
    );
};

export default SavedMessage;
