// Built-in
import React, { useState, useEffect } from 'react';
// External libraries
import CIcon from '@coreui/icons-react';
import {
    CButton,
    CContainer,
    CForm,
    CFormGroup,
    CImg,
    CInput,
    CInputGroup,
    CInputGroupPrepend,
    CInputGroupText,
    CLabel,
    CRow,
    CSpinner,
} from '@coreui/react';
import { Auth } from 'aws-amplify';
import _ from 'lodash';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
// Internal project-specific
import { ErrorBoundary, Toast, ToastProps, When } from '../../../Components';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from '../../../redux-toolkit/reducer';
import { getUserByTokenSignUp, resetExtractedToken } from '../../../redux-toolkit/reducer/userReducer';
import '../login/styles.scss';

import { GENERIC_ERROR_MESSAGE_1, TOAST_FADEOUT, useQuery } from '../../../utils';

const loadingLoader = (
    <div className="pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
);

const CompleteNewLogin = () => {
    const dispatch = useDispatch();
    const [password, setPassword] = useState('');
    const [disableAll, setDisableAll] = useState(false);
    const [authLoading, setAuthLoading] = useState(false);
    const queryParam = useQuery();
    const [toastVals, setToastVals] = useState({ type: '', message: '', msgHeader: '', show: false } as ToastProps);
    const history = useHistory();
    const passwordRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[-+_!@#$%^&*.,?]).+$');
    const [showPassword, setShowPassword] = useState(false);

    const { extractedToken, loading, error, errorObj } = useSelector(
        ({ user }: RootState) => ({
            extractedToken: user.extractedToken,
            loading: user.loading,
            error: user.error,
            errorObj: user.errorObj,
        }),
        _.isEqual,
    );

    const completeSignUp = () => {
        setAuthLoading(true);
        setDisableAll(true);
        const userDetails = { ...extractedToken };
        Auth.signUp({
            username: userDetails.email,
            password: password,
            attributes: {
                email: userDetails.email,
                given_name: userDetails.first_name,
                family_name: userDetails.last_name,
                name: userDetails.fullName,
            },
        })
            .then((res) => {
                if (Object.keys(res).length > 0 && res.userConfirmed) {
                    localStorage.setItem('tempEmail', JSON.stringify(userDetails.email));
                    setToastVals({
                        type: 'success',
                        message: 'Registration complete. You will be redirected to login screen in few seconds.',
                        msgHeader: 'Welcome aboard!',
                        show: true,
                    });
                    setTimeout(() => {
                        setToastVals({ type: '', message: '', msgHeader: '', show: false } as ToastProps);
                        redirectionBasedOnAppPermissions(userDetails);
                    }, TOAST_FADEOUT);
                }
            })
            .catch((err: any) => {
                setToastVals({
                    type: 'error',
                    message: err.message,
                    msgHeader: 'Error!',
                    show: true,
                });
                setTimeout(() => {
                    setToastVals({ type: '', message: '', msgHeader: '', show: false } as ToastProps);
                }, TOAST_FADEOUT);
                setDisableAll(true);
                setAuthLoading(false);
            });
    };

    /**
     * To check organization privileges and redirect to appropriate application's login page
     * @param userObj
     */
    const redirectionBasedOnAppPermissions = (userObj: any) => {
        if (
            userObj.orgsPrivileges &&
            userObj.orgsPrivileges[0].privileges &&
            userObj.orgsPrivileges[0].privileges.length === 1 &&
            userObj.orgsPrivileges[0].privileges.includes('booking-app')
        ) {
            dispatch(resetExtractedToken());
            window.location.href = `${process.env.REACT_APP_BOOKING_APP_URL}`;
        } else {
            dispatch(resetExtractedToken());
            history.push('/login');
        }
    };

    useEffect(() => {
        if (queryParam.get('vc')) {
            const token = queryParam.get('vc');
            if (token && token !== undefined && token !== '') {
                dispatch(getUserByTokenSignUp(token));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryParam]);

    useEffect(() => {
        if (
            extractedToken &&
            Object.keys(extractedToken).length &&
            extractedToken.userAccountStatus &&
            extractedToken.userAccountStatus === 'active'
        ) {
            redirectionBasedOnAppPermissions(extractedToken);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [extractedToken]);

    return (
        <>
            <ErrorBoundary>
                <When isTrue={loading}>{loadingLoader}</When>
                <When
                    isTrue={
                        !loading &&
                        !error &&
                        extractedToken &&
                        extractedToken.email !== null &&
                        extractedToken.email !== undefined &&
                        extractedToken.email !== ''
                    }
                >
                    <CContainer className="d-flex flex-column login">
                        <div className="justify-content-start">
                            <CImg
                                onClick={() => history.push('/login')}
                                className="logo custom-cusor-pointer"
                                src={'logo/large-without-sub.svg'}
                                alt="Noro"
                            />
                        </div>
                        <CRow className="flex-grow-1 justify-content-center align-items-center">
                            <CForm className="login-form">
                                <h1 className="white text-center">Please Register</h1>
                                <CFormGroup className="custom-input lg bottom-margin-30">
                                    <CLabel className="body4-secondary white-important">Email</CLabel>
                                    <CInputGroup className="mb-3">
                                        <CInputGroupPrepend>
                                            <CInputGroupText>
                                                <CIcon name="cil-user" />
                                            </CInputGroupText>
                                        </CInputGroupPrepend>
                                        <CInput
                                            type="text"
                                            defaultValue={extractedToken.email}
                                            disabled
                                            placeholder="Registered email"
                                        />
                                    </CInputGroup>
                                </CFormGroup>
                                <CFormGroup className="custom-input lg bottom-margin-15">
                                    <CLabel className="body4-secondary white-important">Password</CLabel>
                                    <CInputGroup className="mb-2">
                                        <CInputGroupPrepend>
                                            <CInputGroupText>
                                                <CIcon name="cil-lock-locked" />
                                            </CInputGroupText>
                                        </CInputGroupPrepend>
                                        <CInput
                                            disabled={disableAll}
                                            type={showPassword ? 'text' : 'password'}
                                            name="password"
                                            onChange={(e: any) => {
                                                setPassword(e.target.value);
                                            }}
                                            placeholder="Define password"
                                        />
                                        {!showPassword ? (
                                            <VisibilityIcon
                                                className="password show-password sign-up"
                                                onClick={() => setShowPassword(true)}
                                            />
                                        ) : (
                                            <VisibilityOffIcon
                                                className="password hide-password sign-up"
                                                onClick={() => setShowPassword(false)}
                                            />
                                        )}
                                    </CInputGroup>
                                </CFormGroup>

                                <div className="password-policy-container mb-4">
                                    <div className="section">
                                        <div className="dot"></div>
                                        <div className="policy-text">Eight characters minimum</div>
                                    </div>
                                    <div className="section">
                                        <div className="dot"></div>
                                        <div className="policy-text">One lowercase character</div>
                                    </div>
                                    <div className="section">
                                        <div className="dot"></div>
                                        <div className="policy-text">One uppercase character</div>
                                    </div>
                                    <div className="section">
                                        <div className="dot"></div>
                                        <div className="policy-text">One special character</div>
                                    </div>
                                    <div className="section">
                                        <div className="dot"></div>
                                        <div className="policy-text">One number</div>
                                    </div>
                                </div>

                                <CFormGroup className="custom-input lg bottom-margin-30">
                                    <CButton
                                        className="btn-custom btn-primary x-lg btn-width"
                                        disabled={
                                            password === '' ||
                                            password === null ||
                                            password === undefined ||
                                            password.length < 8 ||
                                            disableAll ||
                                            !passwordRegex.test(password)
                                        }
                                        onClick={() => {
                                            completeSignUp();
                                        }}
                                    >
                                        {authLoading ? (
                                            <>
                                                <CSpinner grow={true} size="sm" />
                                            </>
                                        ) : (
                                            <>Complete Registration</>
                                        )}
                                    </CButton>
                                </CFormGroup>
                            </CForm>
                        </CRow>
                        <Toast toastVals={toastVals}></Toast>
                    </CContainer>
                </When>
                <When isTrue={!loading && error}>
                    <CContainer className="d-flex flex-column login">
                        <div className="justify-content-start">
                            <CImg className="logo" src={'logo/large-without-sub.svg'} alt="Noro" />
                        </div>
                        <CRow className="flex-grow-1 justify-content-center align-items-center">
                            <CForm className="login-form">
                                <h1 className="white text-center mb-5">Error!</h1>
                                <h5 className="white text-center">
                                    {error && errorObj && Object.keys(errorObj).length > 0 && errorObj.message && (
                                        <span>{errorObj.message}</span>
                                    )}
                                    {error && errorObj && Object.keys(errorObj).length > 0 && !errorObj.message && (
                                        <span>{GENERIC_ERROR_MESSAGE_1}</span>
                                    )}
                                </h5>
                            </CForm>
                        </CRow>
                        <Toast toastVals={toastVals}></Toast>
                    </CContainer>
                </When>
            </ErrorBoundary>
        </>
    );
};

export default CompleteNewLogin;
