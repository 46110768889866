import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import rootReducer from '../reducer';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const customizedMiddleware = getDefaultMiddleware({
    serializableCheck: false,
});

const persistConfig = {
    key: 'root',
    storage,
    blacklist: ['ably', 'organization'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore(
    {
        reducer: persistedReducer,
        devTools: true,
        middleware: customizedMiddleware,
    },
    //composeWithDevTools()
);
export const persistor = persistStore(store);
