// Built-in
import React, { useState, useLayoutEffect } from 'react';

// External libraries
import ReactSlider from 'react-slider';

// Internal project-specific
import When from '../when/When';

/**
 * The below code is for the single-directional slider component
 * that is used in crop and rotate screen.
 * Marks and renderMark are only when you want to put your custom marker points on the slider
 */

const HorizontalSlider = ({ disabled, sliderValue, leftIcon, rightIcon, initial, end, isInvert = false, ...rest }) => {
    const [slider, setSlider] = useState(sliderValue);
    const { onChangeSlider, step, marks, stepText1, stepText2, stepText3, stepText4, type, sliderCase } = rest;

    const onChange = (value) => {
        if (value >= initial && value <= end && !disabled) {
            setSlider(value);
            onChangeSlider(value);
        }
    };

    useLayoutEffect(() => {
        setSlider(sliderValue);
    }, [sliderValue]);

    return (
        <React.Fragment>
            <div className={'d-flex volume-slider-container single-direction'}>
                <button
                    disabled={disabled}
                    className={`${disabled ? 'disabled' : 'enabled'} btn-circle btn-circle-sm4 right-margin ${
                        sliderCase && sliderCase === 'screenOrientation' ? 'screen-orient' : ''
                    }`}
                    // for rotation slider jump from -90,0,90
                    onClick={() => {
                        if (!disabled) onChange(slider - (step ? step : (end - initial) / 100));
                    }}
                >
                    <i className={disabled ? `icon-disabled icon-${leftIcon}` : `icon-enabled icon-${leftIcon}`} />
                </button>
                <div className="slidecontainer">
                    {marks === null ||
                        (marks === undefined && (
                            <>
                                <div className="slider-separator sep1" />
                            </>
                        ))}
                    {stepText1 && (
                        <div
                            className={`slider-text s-text1 body5-primary ${
                                marks !== undefined && marks !== null && marks.length > 0
                                    ? 's-text-mark1'
                                    : type !== undefined && type !== null && type === 'projectorOrientation'
                                    ? 's-text1-small'
                                    : ''
                            }`}
                        >
                            {stepText1}
                        </div>
                    )}
                    {marks === null ||
                        (marks === undefined && (
                            <>
                                <div className="slider-separator sep2" />
                            </>
                        ))}
                    <When isTrue={stepText2}>
                        <div
                            className={`slider-text s-text2 body5-primary ${
                                marks !== undefined && marks !== null && marks.length > 0
                                    ? 's-text-mark2'
                                    : type !== undefined && type !== null && type === 'projectorOrientation'
                                    ? 's-text2-small'
                                    : ''
                            }`}
                        >
                            {stepText2}
                        </div>
                    </When>
                    <When isTrue={stepText4}>
                        <div
                            className={`slider-text s-text4 body5-primary ${
                                marks !== undefined && marks !== null && marks.length > 0
                                    ? 's-text-mark4'
                                    : type !== undefined && type !== null && type === 'projectorOrientation'
                                    ? 's-text4-small'
                                    : ''
                            }`}
                        >
                            {stepText4}
                        </div>
                    </When>
                    <ReactSlider
                        className={'horizontal-slider single-direction'}
                        thumbClassName="example-thumb"
                        trackClassName="example-track"
                        value={slider}
                        min={initial}
                        max={end}
                        onChange={onChange}
                        invert={isInvert}
                        step={step ? step : 1}
                        disabled={disabled}
                        marks={marks && marks !== null && marks !== undefined && marks.length > 0 ? marks : false}
                        renderMark={
                            marks &&
                            marks !== null &&
                            marks !== undefined &&
                            marks.length > 0 &&
                            ((props) => {
                                // eslint-disable-next-line react/prop-types
                                switch (props.key) {
                                    case 0:
                                    case 90:
                                        // eslint-disable-next-line react/prop-types
                                        props.style.left = props.style.left + 7;
                                        break;
                                    case 180:
                                        // eslint-disable-next-line react/prop-types
                                        props.style.left = props.style.left + 15;
                                        break;
                                    default:
                                }
                                return <span {...props}></span>;
                            })
                        }
                    />
                    {marks === null || (marks === undefined && <div className="slider-separator sep3" />)}
                    <When isTrue={stepText3}>
                        <div
                            className={`slider-text s-text3 body5-primary ${
                                marks !== undefined && marks !== null && marks.length > 0
                                    ? 's-text-mark3'
                                    : type !== undefined && type !== null && type === 'projectorOrientation'
                                    ? 's-text3-small'
                                    : ''
                            }`}
                        >
                            {stepText3}
                        </div>
                    </When>
                </div>
                <button
                    disabled={disabled}
                    className={`${disabled ? 'disabled' : 'enabled'} btn-circle btn-circle-sm4 left-margin ${
                        sliderCase && sliderCase === 'screenOrientation' ? 'screen-orient' : ''
                    }`}
                    // for rotation slider jump from -90,0,90
                    onClick={() => {
                        if (!disabled) onChange(slider + (step ? step : (end - initial) / 100));
                    }}
                >
                    <i className={disabled ? `icon-disabled icon-${rightIcon}` : `icon-enabled icon-${rightIcon}`} />
                </button>
            </div>
        </React.Fragment>
    );
};
export default HorizontalSlider;
