import { combineReducers } from '@reduxjs/toolkit';
import curatorReducer from './curatorReducer';
import loginReducer from './loginReducer';
import organizationReducer from './organizationReducer';
import portalReducer from './portalReducer';
import sidebarReducer from './sidebarReducer';
import userReducer from './userReducer';
import orgAdminReducer from './orgAdminReducer';
import cameraConfigReducer from './cameraConfigReducer';
import screenshotReducer from './screenshotReducer';
import authReducer from './authReducer';
import bookingReducer from './bookingReducer';
import toastReducer from './toastReducer';
import ioConfigReducer from './ioConfigReducer';
import portalActivity from './portalActivityReducer';
import orgSettingReducer from './orgSettingReducer';
import chartReducer from './chartReducer';
import connectionTitleReducer from './connectionTitleReducer';
import ablyReducer from './ablyReducer';
import groupReducer from './groupReducer';

const reducer = combineReducers({
    organization: organizationReducer,
    sidebar: sidebarReducer,
    user: userReducer,
    curator: curatorReducer,
    portal: portalReducer,
    login: loginReducer,
    orgAdmin: orgAdminReducer,
    cameraConfig: cameraConfigReducer,
    screenshot: screenshotReducer,
    auth: authReducer,
    booking: bookingReducer,
    toast: toastReducer,
    ioConfig: ioConfigReducer,
    activity: portalActivity,
    orgSetting: orgSettingReducer,
    chart: chartReducer,
    connectionTitle: connectionTitleReducer,
    ably: ablyReducer,
    group: groupReducer,
});

export type RootState = ReturnType<typeof reducer>;

export default reducer;
