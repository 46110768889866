const InfoIcon = (props: any) => {
    return (
        <svg width="40" height="40" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="ic/info">
                <g id="Union">
                    <path
                        d="M13 8C13 8.55229 12.5523 9 12 9C11.4477 9 11 8.55229 11 8C11 7.44772 11.4477 7 12 7C12.5523 7 13 7.44772 13 8Z"
                        fill="white"
                    />
                    <path
                        d="M12 11C11.4477 11 11 11.4477 11 12V16C11 16.5523 11.4477 17 12 17C12.5523 17 13 16.5523 13 16V12C13 11.4477 12.5523 11 12 11Z"
                        fill="white"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M3.34361 3.51604C4.40322 2.37924 5.90727 1.75 7.66549 1.75H16.3345C18.0965 1.75 19.6013 2.37881 20.6606 3.51641C21.7124 4.64596 22.2505 6.19597 22.2505 7.916V16.084C22.2505 17.804 21.7124 19.3541 20.6604 20.4836C19.601 21.6213 18.0959 22.25 16.3335 22.25H7.66549C5.90342 22.25 4.39882 21.6212 3.33978 20.4835C2.28828 19.3539 1.75049 17.8039 1.75049 16.084V7.916C1.75049 6.19486 2.29127 4.64503 3.34361 3.51604ZM4.80662 4.87971C4.1567 5.57697 3.75049 6.61014 3.75049 7.916V16.084C3.75049 17.3911 4.1552 18.4241 4.80369 19.1208C5.44465 19.8093 6.39756 20.25 7.66549 20.25H16.3335C17.602 20.25 18.5555 19.8092 19.1968 19.1206C19.8456 18.4239 20.2505 17.391 20.2505 16.084V7.916C20.2505 6.60903 19.8456 5.57604 19.1969 4.87934C18.5557 4.19069 17.6024 3.75 16.3345 3.75H7.66549C6.40271 3.75 5.44926 4.19026 4.80662 4.87971Z"
                        fill="white"
                    />
                </g>
            </g>
        </svg>
    );
};

export default InfoIcon;
