import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import { setOnchangeLoader } from '../redux-toolkit/reducer/portalReducer';
import { RootState } from '../redux-toolkit/reducer';
interface AutoSaveProps {
    props: {
        addPortal(obj: any): any;
        updatePortal(obj: any): any;
        isAdd: boolean;
    };
    params: {
        orgId: string;
        portalId: string;
    };
}

export default function useAutoSaveNodeHook({ props, params }: AutoSaveProps) {
    const dispatch = useDispatch();
    const { orgId } = useSelector(
        ({ organization }: RootState) => ({
            orgId: organization.selectedOrgId,
        }),
        isEqual,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const savingNodeData = React.useCallback(
        debounce((data, callBackFn = null) => {
            const updatedPortal = { ...data };
            const updatedPortalObj = {
                orgId: params.orgId !== '' ? params.orgId : orgId,
                portalId: params.portalId,
                updatedPortal: updatedPortal,
            };
            props.updatePortal(updatedPortalObj);
            dispatch(setOnchangeLoader({ isChangeLoader: true, isSavedloader: false }));
            if (callBackFn) {
                callBackFn();
            }
        }, 3000),
        [],
    );

    return {
        savingNodeData,
    };
}
