import { apiGet } from './axios';
import { ENDPOINT_DEV } from './configs';

export const agentLogsAPI = async (org_id: string, portal_id: string) => {
    return await apiGet(ENDPOINT_DEV + `organization/${org_id}/portal/${portal_id}/agent-logs`);
};

export const latestAgentLogsAPI = async (org_id: string, portal_id: string, count: Number) => {
    return await apiGet(ENDPOINT_DEV + `organization/${org_id}/portal/${portal_id}/${count}/fetch-agent-logs`);
};
