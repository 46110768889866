import React from 'react';

const DeleteIcon = (props: any) => {
    return (
        <svg
            width={props.size ?? 24}
            height={props.size ?? 24}
            viewBox={props.viewBox ?? `0 0 24 24`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M19.3247 9.46799C19.3247 9.46799 18.7817 16.203 18.4667 19.04C18.3167 20.395 17.4797 21.189 16.1087 21.214C13.4997 21.261 10.8877 21.264 8.2797 21.209C6.9607 21.182 6.1377 20.378 5.9907 19.047C5.6737 16.185 5.1337 9.46799 5.1337 9.46799M20.708 6.23949H3.75M17.4404 6.23949C16.6554 6.23949 15.9794 5.68449 15.8254 4.91549L15.5824 3.69949C15.4324 3.13849 14.9244 2.75049 14.3454 2.75049H10.1124C9.5334 2.75049 9.0254 3.13849 8.8754 3.69949L8.6324 4.91549C8.4784 5.68449 7.8024 6.23949 7.0174 6.23949"
                stroke={props.color ?? '#F44336'}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
        </svg>
    );
};

export default DeleteIcon;
