import { useEffect, useState } from 'react';
import { Hub } from 'aws-amplify';
import { getAndStoreUpdatedToken } from '../utils/Utils';

const useAuthListener = () => {
    const [showLogoutDialog, setShowLogoutDialog] = useState(false);
    /**
     * Checks if the current session is valid or not. Shows expired session popup if invalid session.
     */
    useEffect(() => {
        const getUserAuthCurrentState = ({ payload }) => {
            console.log('payload.event', payload.event);
            switch (payload.event) {
                case 'signOut':
                    console.log('payload.event signOut');
                    setShowLogoutDialog(true);
                    break;
                case 'tokenRefresh':
                    // eslint-disable-next-line no-console
                    console.log('tokenRefresh:');
                    getAndStoreUpdatedToken();
                    break;
                default:
                    break;
            }
        };
        console.log('enter hub listner');
        Hub.listen('auth', getUserAuthCurrentState);

        return () => {
            console.log('remove hub listner');
            Hub.remove('auth', getUserAuthCurrentState);
        };
    }, []);

    useEffect(() => {
        console.log('showLogoutDialog', showLogoutDialog);
    }, [showLogoutDialog]);
};

export default useAuthListener;
