import React, { useEffect, useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { reinviteOrgAdminByOrgId } from '../../redux-toolkit/reducer/orgAdminReducer';
import { setToast } from '../../redux-toolkit/reducer/toastReducer';

const OrgAdminStatusCard = (props: any) => {
    const dispatch = useDispatch();
    const params: { orgId: ''; email: '' } = useParams();
    const [showResendInviteBtn, setShowResendInviteBtn] = useState(false);

    useEffect(() => {
        if (
            props?.activationStatus !== null &&
            props?.activationStatus !== undefined &&
            props?.activationStatus &&
            props?.activationStatus === 'expired'
        ) {
            setShowResendInviteBtn(true);
        }
    }, [props.activationStatus]);

    const resendInvitation = () => {
        const reinviteOrgAdminDto = {
            org_id: params.orgId,
            email: props.userEmail,
        };
        dispatch(reinviteOrgAdminByOrgId(reinviteOrgAdminDto));
        setShowResendInviteBtn(false);
        dispatch(
            setToast({
                type: 'success',
                message:
                    'The user has successfully been re-invited. An email has been sent to his/her address to complete the registration.',
                msgHeader: '',
                show: true,
            }),
        );
    };

    const statusChip = () => {
        if (props.activationStatus) {
            if (props.activationStatus === 'invited') {
                return <div className="status-text invited body5-secondary">Invited</div>;
            } else if (props.activationStatus === 'active') {
                return <div className="status-text available body5-secondary">Active</div>;
            } else if (props.activationStatus === 'expired') {
                return (
                    <>
                        <div className="status-text expired body5-secondary">Expired</div>
                        {showResendInviteBtn && props.isAllowedToModifyUsers && (
                            <span className="regenerate-key ml-2" onClick={resendInvitation}>
                                Re-send invitation
                            </span>
                        )}
                        {!showResendInviteBtn && (
                            <span className="spinner-container">
                                <Spinner animation="border" variant="light">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            </span>
                        )}
                    </>
                );
            } else {
                return <div className="status-text not-found body5-secondary">-</div>;
            }
        } else {
            return <div className="status-text in-maintenance body5-secondary">Status Not Found</div>;
        }
    };

    return (
        <>
            <div className="status-card">
                <div className="body1-primary portal-status-chip align-items-center">
                    Status
                    {statusChip()}
                </div>
                <div className="body1-primary">
                    Joined at{' '}
                    {props.createdOn !== null && props.createdOn !== undefined && (
                        <span className="portalCreationDate">
                            {new Date(props.createdOn).toLocaleDateString('en-US', {
                                month: '2-digit',
                                day: '2-digit',
                                year: 'numeric',
                            })}
                        </span>
                    )}
                    {(props.createdOn === null || props.createdOn === undefined) && (
                        <span className="portalCreationDate">-</span>
                    )}
                </div>
            </div>
        </>
    );
};

export default OrgAdminStatusCard;
