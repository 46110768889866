// Built-in
import React, { useState, useEffect } from 'react';

// Internal project-specific
import { ServiceWorkerUpdaterProps, withServiceWorkerUpdater } from '../../utils';

const AppServiceUpdater = (props: ServiceWorkerUpdaterProps) => {
    const { newServiceWorkerDetected, onLoadNewServiceWorkerAccept } = props;
    const [showModal, setShowModal] = useState<boolean>(false);
    const [modalHandle, setModalHandle] = useState<boolean>(false);

    const updateNow = () => {
        setShowModal(false);
        onLoadNewServiceWorkerAccept();
    };

    useEffect(() => {
        if (newServiceWorkerDetected && !modalHandle) {
            setModalHandle(true);
            setShowModal(true);
        }
    }, [newServiceWorkerDetected, modalHandle]);

    useEffect(() => {
        if (newServiceWorkerDetected && showModal) {
            updateNow();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newServiceWorkerDetected, showModal]);

    return <></>;
};

export default withServiceWorkerUpdater(AppServiceUpdater);
